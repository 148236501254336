import { Controller } from "@hotwired/stimulus"
import Trix from "trix"

export default class extends Controller {
  static targets = ['editor']
  connect() {
    console.log("Test Controller Attached!");
    document.addEventListener("trix-before-initialize", () => {
      console.log('Trix Detected!');
      this.el = document.querySelector('trix-editor');
    })

  }

  test() {
    var attachment = new Trix.Attachment({ sgid: null, content: '<span>@trix</span>' })
    this.editorTarget.editor.insertAttachment(attachment);
  }
}
