import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    // Base State
    csrfToken: '',
    userId: null,
    userRole: null,
    opportunityId: null,
    clientDocuments: [],
    categories: [],
    documents: [],
    staffUsers: [],
    clients: [],
    builders: [],
    notRequiredSubCats: [],
    restrictedSubCategories: [],
    userPermissions:{
      canEdit: false
    },


    // Review State
    reviewModalOpen: false,
    reviewModalDocumentId: null,
    reviewLoading: false,
    reviewCompleted: false,
    reviewUserId: null,
    reviewContext: '',

    // DocuSign State
    signingModalOpen: false,
    signingModalDocumentId: null,
    signingLoading: false,
    signingCompleted: false,
    signingClientContacts: [],
    signingBuilderContacts: [],
    signingStaffContacts: [
      {
        name: 'Jason',
        email: 'sales@theshedhouse.com.au',
        id: 2
      }
    ],
    signingDocumentFinalised: false,
    signingReviewNotRequired: false,
    emailSubject: 'The Shed House is Requesting Document Signing',
    emailBody: '',

    // DocuSign Retraction State
    retractModalOpen: false,
    retractModalDocumentId: null,
    retractLoading: false,
    retractCompleted: false,
    retractText: '',

    // DocuSign Resending State
    resendLoading: false,
    resendCompleted: false,
    resendDocumentId: null,

    // Manually Signing State
    manuallySigningModalOpen: false,
  },
  mutations: {
    openManuallySigningModal(state, id){
      state.manuallySigningModalOpen = true;
      state.signingModalDocumentId = id;
    },
    closeManuallySigningModal(state) {
      state.manuallySigningModalOpen = false;
      state.signingModalDocumentId = null;
    },
    removeNotRequiredSubCategory(state, id) {
      const index = state.notRequiredSubCats.findIndex(sc => sc === id);
      state.notRequiredSubCats.splice(index, 1);
    },
    addNotRequiredSubCategory(state, id) {
      state.notRequiredSubCats.push(id);
    },
    setNotRequiredSubCategories(state, ids) {
      state.notRequiredSubCats = ids;
    },
    setUserPermissions(state, userPermissions) {
      state.userPermissions = userPermissions;
    },
    setRestrictedSubCategories(state, restrictedSubCategories) {
      state.restrictedSubCategories = restrictedSubCategories;
    },
    setCsrf(state, token) {
      state.csrfToken = token;
    },
    openReviewModal(state, id) {
      state.reviewModalOpen = true;
      state.reviewModalDocumentId = id;
    },
    closeReviewModal(state) {
      state.reviewModalOpen = false;
      state.reviewModalDocumentId = null;
      state.reviewUserId = null;
      state.reviewContext = '';
    },
    openRetractModal(state, id) {
      state.retractModalOpen = true;
      state.retractModalDocumentId = id;
    },
    updateRetractText(state, text) {
      state.retractText = text;
    },
    closeRetractModal(state) {
      state.retractModalOpen = false;
      state.retractModalDocumentId = null;
      state.retractText = '';
      state.retractLoading = false;
      state.retractCompleted = false;
    },
    setReviewLoading(state, loading) {
      state.reviewLoading = loading;
    },
    setReviewCompleted(state, completed) {
      state.reviewCompleted = completed;
    },
    setRetractLoading(state, loading) {
      state.retractLoading = loading;
    },
    setRetractCompleted(state, completed) {
      state.retractCompleted = completed;
    },
    setResendLoading(state, loading) {
      state.resendLoading = loading;
    },
    setResendCompleted(state, completed) {
      state.resendCompleted = completed;
    },
    updateReviewUserId(state, userId) {
      state.reviewUserId = userId;
    },
    updateReviewContext(state, context) {
      state.reviewContext = context;
    },
    openSigningModal(state, id) {
      state.signingModalOpen = true;
      state.signingModalDocumentId = id;
    },
    closeSigningModal(state) {
      state.signingModalOpen = false;
      state.signingModalDocumentId = null;
      state.signingClientContacts = [];
      state.signingBuilderContacts = [];
      state.signingStaffContacts = [
        {
          name: 'Jason',
          email: 'sales@theshedhouse.com.au',
          id: 2
        }
      ]
      state.signingDocumentFinalised = false;
      state.signingReviewNotRequired = false;
      state.signingLoading = false;
      state.signingCompleted = false;
      state.emailSubject = 'The Shed House is Requesting Document Signing';
      state.emailBody = '';
    },
    setSigningLoading(state, loading) {
      state.signingLoading = loading;
    },
    setSigningCompleted(state, completed) {
      state.signingCompleted = completed;
    },
    addStaffSigner(state, id) {
      if (!id) return;
      const staffIndex = state.staffUsers.findIndex(s => s.id == id);
      state.signingStaffContacts.push(state.staffUsers[staffIndex]);
    },
    removeStaffSigner(state, id) {
      if (!id) return;
      const staffIndex = state.signingStaffContacts.findIndex(s => s.id == id);
      state.signingStaffContacts.splice(staffIndex, 1);
    },
    addClientSigner(state, id) {
      if (!id) return;
      const clientIndex = state.clients.findIndex(c => c.id == id);
      state.signingClientContacts.push(state.clients[clientIndex]);
    },
    addBuilderSigner(state, id) {
      if (!id) return;
      const clientIndex = state.builders.findIndex(c => c.id == id);
      state.signingBuilderContacts.push(state.builders[clientIndex]);
    },
    removeClientSigner(state, id) {
      if (!id) return;
      const clientIndex = state.signingClientContacts.findIndex(c => c.id == id);
      state.signingClientContacts.splice(clientIndex, 1);
    },
    removeBuilderSigner(state, id) {
      if (!id) return;
      const clientIndex = state.signingBuilderContacts.findIndex(c => c.id == id);
      state.signingBuilderContacts.splice(clientIndex, 1);
    },
    removeCurrent(state, id) {
      const newCurrentDoc = state.documents.find(d => d.id == id);

      state.documents = state.documents.map(d => {
        if (
          d.client_document_category_id === newCurrentDoc.client_document_category_id &&
          d.client_document_sub_category_id === newCurrentDoc.client_document_sub_category_id
        ) {
          return {
            ...d,
            current: false
          }
        } else {
          return d;
        }
      });
    },
    setDocumentFinalised(state, val) {
      state.signingDocumentFinalised = val;
    },
    setReviewNotRequired(state, val) {
      state.signingReviewNotRequired = val;
    },
    setOpportunityId(state, id) {
      state.opportunityId = id;
    },
    setUserRole(state, role) {
      state.userRole = role;
    },
    setUserId(state, id) {
      state.userId = id;
    },
    setClientDocuments(state, documents) {
      state.clientDocuments = documents;
    },
    setCategories(state, categories) {
      state.categories = categories;
    },
    setDocuments(state, documents) {
      state.documents = documents;
    },
    setUsers(state, users) {
      state.staffUsers = users;
    },
    setClients(state, clients) {
      state.clients = clients;
    },
    setBuilders(state, builders) {
      state.builders = builders;
    },
    setEmailSubject(state, subject) {
      state.emailSubject = subject;
    },
    setEmailBody(state, body) {
      state.emailBody = body;
    },
    addDocument(state, document) {
      // Is the new document 'current'? If so, all others with the same category ID
      // should be set to non-current
      let newDocumentList = [
        ...state.documents.map(d => {
          const sameCategory = d.client_document_category_id == document.client_document_category_id;
          const sameSubCategory = d.client_document_sub_category_id == document.client_document_sub_category_id;

          // TODO: Will have to add logic for multiple or single categories
          if (sameCategory && sameSubCategory && document.current && !document.multiple_category) {
            return {
              ...d,
              current: false
            }
          } else {
            return d;
          }
        }),
        document
      ];

      state.documents = newDocumentList;
    },
    deleteDocument(state, { documentId, newCurrent }) {
      const docIndex = state.documents.findIndex(d => d.id == documentId);
      state.documents.splice(docIndex, 1);

      if (newCurrent) {
        const newCurrentIndex = state.documents.findIndex(d => d.id == newCurrent);

        if (newCurrentIndex > -1) {
          Vue.set(state.documents[newCurrentIndex], 'current', true);
        }
      }
    },
    updateDocument(state, document) {
      const docIndex = state.documents.findIndex(d => d.id == document.id);
      if(document.error != null) {
        alert(document.error);
      }

      Vue.set(state.documents, docIndex, document);
    },
    togglePreviousCurrentDocuments(state, document) {
      // Update 'current' status of competing documents
      if (document.current && !document.multiple_category) {
        state.documents = state.documents.map(d => {
          if (
            d.current &&
            d.id !== document.id &&
            d.client_document_sub_category_id === document.client_document_sub_category_id &&
            d.client_document_category_id === document.client_document_category_id
            ) {
            return {
              ...d,
              current: false
            }
          } else {
            return d;
          }
        });
      }
    }
  },
  actions: {
    toggleNotRequired({ state, commit }, id ) {


      const headers = new Headers({
        'Content-Type': 'application/json',
        'x-csrf-token': state.csrfToken
      });

      if(state.notRequiredSubCats.includes(id)) {
        const url = `/staff/opportunities/${state.opportunityId}/opp_client_sub_categories/${id}`;

        fetch(url, {method: 'DELETE', headers: headers}).then(()=>{
          commit('removeNotRequiredSubCategory', id)
        })
      } else {
        const url = `/staff/opportunities/${state.opportunityId}/opp_client_sub_categories`;

        const body = JSON.stringify({opportunity_id: state.opportunityId, client_document_sub_category_id: id})

        fetch(url, {method: 'POST', headers: headers, body: body}).then(()=>{
          commit('addNotRequiredSubCategory', id)
        })
      }
    },

    loadDocuments({ state, commit }) {
      const url = `/staff/opportunities/${state.opportunityId}/client/documents.json`;

      fetch(url)
        .then(res =>  res.json())
        .then(data => {
          commit('setClientDocuments', data.client_documents);
          commit('setCategories', data.categories);
          commit('setDocuments', data.documents);
          commit('setUsers', data.users);
          commit('setClients', data.contacts);
          commit('setBuilders', data.builders);
          commit('setNotRequiredSubCategories', data.not_required_sub_categories);
          commit('setUserPermissions', data.user_permissions)
          commit('setRestrictedSubCategories', data.restricted_sub_categories)
        });
    },
    deleteDocument({ state, commit }, documentId) {
      const url = `/staff/opportunities/${state.opportunityId}/client_documents/${documentId}.json`;
      const headers = new Headers({
        'Content-Type': 'application/json',
        'x-csrf-token': state.csrfToken
      });

      fetch(url, { method: 'DELETE', headers })
        .then(res =>  res.json())
        .then(data => {
          commit('deleteDocument', { documentId, newCurrent: data.new_current });
        });
    },
    approveDocument({ state, commit }, documentId) {
      const url = `/staff/opportunities/${state.opportunityId}/client_documents/${documentId}.json`;
      const headers = new Headers({
        'Content-Type': 'application/json',
        'x-csrf-token': state.csrfToken      
      });
      const body = JSON.stringify({ client_document: { approved_by_id: state.userId }});

      fetch(url, { method: 'PATCH', headers, body })
        .then(res => res.json())
        .then(data => {
          commit('updateDocument', data);
        });

    },
    unapproveDocument({ state, commit }, documentId) {
      const url = `/staff/opportunities/${state.opportunityId}/client_documents/${documentId}.json`;
      const headers = new Headers({
        'Content-Type': 'application/json',
        'x-csrf-token': state.csrfToken      
      });
      const body = JSON.stringify({ client_document: { approved_by_id: null }});

      fetch(url, { method: 'PATCH', headers, body })
        .then(res => res.json())
        .then(data => {
          commit('updateDocument', data);
        });
    },
    reviewDocument({ state, commit }, documentId) {
      const url = `/staff/opportunities/${state.opportunityId}/client_documents/${documentId}.json`;
      const headers = new Headers({
        'Content-Type': 'application/json',
        'x-csrf-token': state.csrfToken      
      });
      const body = JSON.stringify({ client_document: { reviewed_date: new Date(), review_status: 'Reviewed' }});

      fetch(url, { method: 'PATCH', headers, body })
        .then(res => res.json())
        .then(data => {
          commit('updateDocument', data);
        });
    },
    cancelReview({ state, commit }, documentId) {
      const url = `/staff/opportunities/${state.opportunityId}/client_documents/${documentId}.json`;
      const headers = new Headers({
        'Content-Type': 'application/json',
        'x-csrf-token': state.csrfToken      
      });
      const body = JSON.stringify({ client_document: { reviewed_date: null, review_request_date: null, review_status: 'No Review Required' }});

      fetch(url, { method: 'PATCH', headers, body })
        .then(res => res.json())
        .then(data => {
          commit('updateDocument', data);
        });
    },
    dropDocument({ state, commit }, { documentId, categoryIds, index }) {
      let details = {
        client_document: {
          client_document_sub_category_id: categoryIds.subCategoryId,
          position: index,
          current: true  
        }
      }

      // Only add a category ID if no sub-category ID exists
      if (!details.client_document.client_document_sub_category_id) {
        details.client_document.client_document_category_id = categoryIds.categoryId;
      }

      const url = `/staff/opportunities/${state.opportunityId}/client_documents/${documentId}.json`;
      const headers = new Headers({
        'Content-Type': 'application/json',
        'x-csrf-token': state.csrfToken
      });
      const body = JSON.stringify(details);

      fetch(url, { method: 'PATCH', headers, body })
        .then(res => res.json())
        .then(data => {
          commit('updateDocument', data);
          commit('togglePreviousCurrentDocuments', data);
        });
    },
    moveDocument({ state, commit }, { documentId, categoryIds }) {
      // When a document has moved OUT of the category ID's, nominate a new 'current'
      // document, if there are any previous versions.
      const affectedDocuments = state.documents.filter(d => {
        return d.client_document_category_id == categoryIds.categoryId &&
          d.client_document_sub_category_id == categoryIds.subCategoryId &&
          d.id !== documentId
      });

      if (affectedDocuments.length > 0 && !affectedDocuments[0].multiple_category) {
        let latestVersion = affectedDocuments.reduce((prev, curr) => { 
          if (prev == null || curr.version >= prev.version) return curr;
        }, null);

        latestVersion.current = true;

        commit('updateDocument', latestVersion);
      }
    },
    requestReview({ state, commit, dispatch }, { context }) {
      commit('setReviewLoading', true);
      const url = `/staff/opportunities/${state.opportunityId}/client_documents/${state.reviewModalDocumentId}.json`;
      const headers = new Headers({
        'Content-Type': 'application/json',
        'x-csrf-token': state.csrfToken      
      });
      const params = {
        client_document: {
          review_request_date: new Date(),
          reviewer_au_id: state.reviewUserId,
          review_status: 'Sent For Review'
        }
      }
      const body = JSON.stringify(params);

      fetch(url, { method: 'PATCH', headers, body })
        .then(res => res.json())
        .then(data => {
          commit('updateDocument', data);
          dispatch('sendReviewNotification', { context });
        });

    },
    retractEnvelope({ state, commit }) {
      commit('setRetractLoading', true);
      const url = `/staff/docusign/client_documents/${state.retractModalDocumentId}/retract`;
      const headers = new Headers({
        'Content-Type': 'application/json',
        'x-csrf-token': state.csrfToken      
      });
      const body = JSON.stringify({ voided_reason: state.retractText });

      fetch(url, { method: 'PATCH', headers, body })
        .then(res => res.json())
        .then(data => {
          commit('updateDocument', data);
          commit('setRetractLoading', false);
          commit('setRetractCompleted', true);

          window.setTimeout(() => {
            commit('closeRetractModal');
            commit('setRetractCompleted', false)
          }, 2000);
        });
    },
    resendSigning({ state, commit }, id) {
      commit('setResendLoading', true);
      const url = `/staff/docusign/client_documents/${id}/resend`;
      const headers = new Headers({
        'Content-Type': 'application/json',
        'x-csrf-token': state.csrfToken      
      });

      fetch(url, { method: 'PATCH', headers, signal: AbortSignal.timeout(20000) })
        .then(res => res.json())
        .then(data => {
          commit('updateDocument', data);
          commit('setResendLoading', false);
          commit('setResendCompleted', true);

          window.setTimeout(() => {
            commit('setResendCompleted', false)
          }, 2000);
        })
        .catch(err => {
          commit('setResendLoading', false);
          commit('setResendCompleted', false)
          alert("An unexpected error occurred while attempting to re-send the document for signing. Please try again.")
        });
    },
    manuallySign({ state, commit }) {
      const url = `/staff/opportunities/${state.opportunityId}/client_documents/${state.signingModalDocumentId}.json`;
      const headers = new Headers({
        'Content-Type': 'application/json',
        'x-csrf-token': state.csrfToken
      });
      const params = {
        client_document: {
          status: 'Manually Signed',
          docusign_signed: new Date()
        }
      }
      const body = JSON.stringify(params);

      fetch(url, { method: 'PATCH', headers, body })
          .then(res => res.json())
          .then(data => {
            window.setTimeout(() => {
              commit('updateDocument', data);
              commit('closeManuallySigningModal');
            }, 2000);
          });

    },
    undoManuallySign({ state, commit }) {
      const url = `/staff/opportunities/${state.opportunityId}/client_documents/${state.signingModalDocumentId}.json`;
      const headers = new Headers({
        'Content-Type': 'application/json',
        'x-csrf-token': state.csrfToken
      });
      const params = {
        client_document: {
          status: 'Draft',
          docusign_signed: null
        }
      }
      const body = JSON.stringify(params);

      fetch(url, { method: 'PATCH', headers, body })
          .then(res => res.json())
          .then(data => {
            window.setTimeout(() => {
              commit('updateDocument', data);
              commit('closeManuallySigningModal');
            }, 2000);
          });

    },
    makeActive({ state, commit }, id) {
      const url = `/staff/opportunities/${state.opportunityId}/client_documents/${id}.json`;
      const headers = new Headers({
        'Content-Type': 'application/json',
        'x-csrf-token': state.csrfToken      
      });
      const params = {
        client_document: {
          current: true
        }
      }
      const body = JSON.stringify(params);

      fetch(url, { method: 'PATCH', headers, body })
        .then(res => res.json())
        .then(data => {
          commit('removeCurrent', id);
          commit('updateDocument', data);
        });
    },
    showDocument({ state, commit }, id) {
      const url = `/staff/opportunities/${state.opportunityId}/client_documents/${id}.json`;
      const headers = new Headers({
        'Content-Type': 'application/json',
        'x-csrf-token': state.csrfToken      
      });
      const params = {
        client_document: {
          show_client: true
        }
      }
      const body = JSON.stringify(params);

      fetch(url, { method: 'PATCH', headers, body })
        .then(res => res.json())
        .then(data => {
          commit('updateDocument', data);
        });
    },
    enableSigning({ state, commit }, id) {
      const url = `/staff/opportunities/${state.opportunityId}/client_documents/${id}.json`;
      const headers = new Headers({
        'Content-Type': 'application/json',
        'x-csrf-token': state.csrfToken
      });
      const params = {
        client_document: {
          requires_signing: true
        }
      }
      const body = JSON.stringify(params);

      fetch(url, { method: 'PATCH', headers, body })
          .then(res => res.json())
          .then(data => {
            commit('updateDocument', data);
          });
    },
    disableSigning({ state, commit }, id) {
      const url = `/staff/opportunities/${state.opportunityId}/client_documents/${id}.json`;
      const headers = new Headers({
        'Content-Type': 'application/json',
        'x-csrf-token': state.csrfToken
      });
      const params = {
        client_document: {
          requires_signing: false
        }
      }
      const body = JSON.stringify(params);

      fetch(url, { method: 'PATCH', headers, body })
          .then(res => res.json())
          .then(data => {
            commit('updateDocument', data);
          });
    },
    hideDocument({ state, commit }, id) {
      const url = `/staff/opportunities/${state.opportunityId}/client_documents/${id}.json`;
      const headers = new Headers({
        'Content-Type': 'application/json',
        'x-csrf-token': state.csrfToken      
      });
      const params = {
        client_document: {
          show_client: false
        }
      }
      const body = JSON.stringify(params);

      fetch(url, { method: 'PATCH', headers, body })
        .then(res => res.json())
        .then(data => {
          commit('updateDocument', data);
        });
    },
    sendSigningRequest({ state, commit, dispatch }) {
      commit('setSigningLoading', true);
      const url = `/staff/docusign/client_documents.json`;
      const headers = new Headers({
        'Content-Type': 'application/json',
        'x-csrf-token': state.csrfToken      
      });
      const params = {
        id: state.signingModalDocumentId,
        email_subject: state.emailSubject,
        email_body: state.emailBody,
        staff: state.signingStaffContacts.map(s => s.id),
        recipients: state.signingClientContacts.map(u =>  u.id),
        builders: state.signingBuilderContacts.map(u =>  u.id)
      }
      const body = JSON.stringify(params);

      fetch(url, { method: 'POST', headers, body })
        .then(res => res.json())
        .then(data => {
          commit('updateDocument', data);
          commit('setSigningLoading', false);
          commit('setSigningCompleted', true);

          window.setTimeout(() => {
            commit('closeSigningModal');
            commit('setSigningCompleted', false);
          }, 2000);
        });
    },
    sendReviewActionItem({ state, commit } , { context }) {
      const document = state.documents.find(d => d.id == state.reviewModalDocumentId);
      const url = `/staff/opportunities/${state.opportunityId}/action_items.json`;
      const headers = new Headers({
        'Content-Type': 'application/json',
        'x-csrf-token': state.csrfToken      
      });
      const params = {
        action_item: {
          content:  `Document Review Requested - ${document.name} (${document.file_name}) - ${context ? context : 'No additional notes added'}`,
          status: 'incomplete',
          target_date: new Date(),
          opportunity_id: state.opportunityId,
          affected_record_type: 'ClientDocument',
          affected_record_id: state.reviewModalDocumentId,
          url: `/staff/opportunities/${state.opportunityId}/client/documents#${document.id}`,
          assigned_staff_ids: [state.reviewUserId]
        }
      }
      const body = JSON.stringify(params);
  
      fetch(url, { method: 'POST', headers, body })
        .then(res => res.json())
        .then(data => {
          commit('setReviewLoading', false);
          commit('setReviewCompleted', true);
  
          window.setTimeout(() => {
            commit('closeReviewModal');
            commit('setReviewCompleted', false);
          }, 2000);
        });
    },


    sendReviewNotification({ state, commit } , { context }) {
      const document = state.documents.find(d => d.id == state.reviewModalDocumentId);
      const url = `/staff/opportunities/${state.opportunityId}/client_documents/${state.reviewModalDocumentId}/request_review`;
      const headers = new Headers({
        'Content-Type': 'application/json',
        'x-csrf-token': state.csrfToken
      });

      const params = {
        account_user_id: state.reviewUserId,
      }
      const body = JSON.stringify(params);

      fetch(url, { method: 'POST', headers, body })
          .then(data => {
            commit('setReviewLoading', false);
            commit('setReviewCompleted', true);

            window.setTimeout(() => {
              commit('closeReviewModal');
              commit('setReviewCompleted', false);
            }, 2000);
          });
    },


  },
  getters: {
    documentForSigning(state) {
      if (state.signingModalDocumentId) {
        return state.documents.find(d => d.id == state.signingModalDocumentId);
      } else {
        return null;
      }
    },

    categoriesWithDocuments(state) {
      return state.categories.map(cat => {
        return {
          ...cat,
          documents: state.documents.filter(d => {
            return !d.client_document_sub_category_id &&
              d.client_document_category_id === cat.id
          }),
          sub_categories: cat.sub_categories.map(subCat => {
            return {
              ...subCat,
              documents: state.documents.filter(d => d.client_document_sub_category_id === subCat.id)
            }
          })
        }
      })
    }
  }
});

export default store;
