<template>
  <div>
    <div class="flex space-x-4">
      <div class="flex-auto">
        <label class='font-semibold text-gray-800'>Template Name</label>
        <input type="text" :value="name" @change="name = $event.target.value; saveTemplate()" class="bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block w-full appearance-none leading-normal" placeholder='Schedule template name'>
      </div>
    </div>
    <div class="flex items-center justify-between mt-4">
      <h3 class="font-bold text-xl text-gray-800">Template Bookings</h3>
      <a href="#" @click.prevent="addtemplateBooking" class="inline-flex items-center justify-center px-3 py-1 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-brand-dark hover:bg-brand-light focus:outline-none focus:shadow-outline transition duration-150 ease-in-out">
        <svg class="w-6 h-6 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path></svg>
        Add New Template Booking
      </a>
    </div>
    <div>
      <draggable v-model="templateBookings" @start="drag=true" @end="drag=false; saveTemplate()">
        <div v-for="templateBooking in templateBookings" :key="`template-booking-${templateBooking.id}`">
          <!-- When editing -->
          <div v-if="templateBooking.editing" class="bg-gray-200 rounded px-5 py-3 mt-4">
            <div class="flex items-center">
              <div class="flex-1 flex space-x-2">
                <div class="flex-1">
                  <label class='font-semibold text-gray-800'>Booking Name<sup>*</sup></label>
                  <input type="text" v-model="templateBooking.name" class="bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block w-full appearance-none leading-normal" placeholder='Booking name'>
                </div>
              </div>
            </div>
            <div class="mt-2">
              <label class='font-semibold text-gray-800'>Description</label>
              <textarea v-model="templateBooking.description" class="bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block w-full appearance-none leading-normal" placeholder='Optional booking description'></textarea>
            </div>
            <div class="mt-2">
              <label class='block font-semibold text-gray-800'>Supplier</label>
              <select v-model="templateBooking.supplier_id" class="form-select w-full">
                <option :value="null">Nominate the supplier</option>
                <option v-for="s in suppliers" :key="s.id" :value="s.id">{{ s.name }}</option>
              </select>
            </div>
            <div class="mt-2">
              <div class="flex items-center space-x-2">
                <div class="flex-1">
                  <label class="block font-semibold text-gray-800">Task Nomination</label>
                  <select v-model="templateBooking.chosen_task_id" @change="templateBooking.chosen_task_id = $event.target.value; templateBooking.task_type = 'FunnelTask'" class="form-select w-full">
                    <option :value="null">Nominate the connected task</option>
                    <option v-for="t in tasks" :key="t.id" :value="t.id">{{ t.name }}</option>
                  </select>
                </div>
                <div class="flex-1">
                  <label class="block font-semibold text-gray-800">Sub-Task Nomination</label>
                  <select v-model="templateBooking.chosen_sub_task_id" @change="templateBooking.task_type = 'FunnelSubTask'" class="form-select w-full">
                    <option :value="null" v-if="!templateBooking.chosen_task_id">Nominate a task to view sub-tasks</option>
                    <option :value="null" v-else-if="subTasks(templateBooking.chosen_task_id).length === 0">No sub-tasks in chosen task for scheduler</option>
                    <option v-else :value="null">Nominate the connected sub-task</option>
                    <option v-show="templateBooking.chosen_task_id" :value="st.id" v-for="st in subTasks(templateBooking.chosen_task_id)" :key="st.id">{{ st.name }}</option>
                  </select>
                </div>
              </div>
            </div>
            <a href="#" @click.prevent="templateBooking.editing = false; saveTemplate()" class="inline-flex items-center justify-center mt-2 px-3 py-1 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-brand-dark hover:bg-brand-light focus:outline-none focus:shadow-outline transition duration-150 ease-in-out">
              Save Booking
            </a>
          </div>
          <div v-else-if="(showKept && !templateBooking.discarded_at) || !showKept" class="bg-gray-100 rounded px-5 py-3 mt-3 flex items-center justify-between" :class="templateBooking.discarded_at ? 'bg-red-100' : ''">
            <div class="flex items-center">
              <svg class="w-4 h-4 mr-2 text-gray-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
              <h4 class="font-bold text-gray-800 mr-6">{{ templateBooking.name }}</h4>
            </div>
            <div class="flex items-center">
              <a href="#" @click.prevent="templateBooking.editing = true" class="text-blue-500 mr-4">
                <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"></path></svg>
              </a>
              <a v-if="templateBooking.discarded_at" href="#" @click.prevent="undiscardtemplateBooking(templateBooking.id)" class="text-blue-500">
                <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"></path></svg>
              </a>
              <a v-else href="#" @click.prevent="discardtemplateBooking(templateBooking.id)" class="text-red-500">
                <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path></svg>
              </a>
            </div>
          </div>
        </div>
      </draggable>
      <div class="flex items-center justify-center mt-8 text-blue-500">
        <a href="#" @click.prevent="showKept = !showKept">Toggle Archived Template Bookings</a>
      </div>
    </div>
  </div>
</template>
<script>
  import draggable from 'vuedraggable';
  import axios from 'axios';
  import Vue from 'vue';

  export default {
    components: { draggable },
    data: function() {
      return {
        id: '',
        name: '',
        discarded_at: null,
        templateBookings: [],
        editing: true,
        showKept: true,
        suppliers: [],
        tasks: [],
        sub_tasks: {}
      }
    },
    mounted() {
      this.id = document.getElementById('schedule-template-id').dataset.id;
      this.loadTemplate();
    },
    methods: {
      subTasks(taskId) {
        return this.sub_tasks[taskId];
      },
      addtemplateBooking() {
        this.templateBookings = [
          ...this.templateBookings,
          {
            id: null,
            name: '',
            description: '',
            supplier_id: null,
            task_type: null,
            chosen_task_id: null,
            chosen_sub_task_id: null,
            editing: true,
          }
        ]
      },
      discardtemplateBooking(id) {
        if (confirm('Are you sure you want to archive this booking template?')) {
          const bookingIndex = this.templateBookings.findIndex(t => t.id === id);

          Vue.set(this.templateBookings[bookingIndex], 'discarded_at', new Date());
          this.saveTemplate();
        }
      },
      undiscardtemplateBooking(id) {
        const bookingIndex = this.templateBookings.findIndex(t => t.id === id);

        Vue.set(this.templateBookings[bookingIndex], 'discarded_at', null);
        this.saveTemplate();
      },
      loadTemplate() {
        let url = '/staff/schedule_templates/new.json';

        if (this.id) url = `/staff/schedule_templates/${this.id}.json`;

        axios.get(url)
             .then(res => {
               this.name = res.data.name;
               this.discarded_at = res.data.discarded_at;
               this.templateBookings = res.data.template_bookings || [];
               this.suppliers = res.data.suppliers;
               this.tasks = res.data.tasks;
               this.sub_tasks = res.data.sub_tasks;
             });
      },
      saveTemplate() {
        let url = '/staff/schedule_templates';
        let method = 'POST';

        if (this.id) {
          url = `${url}/${this.id}.json`;
          method = 'PATCH';
        } else {
          url = `${url}.json`;
        }
        const csrf = document.getElementsByName('csrf-token')[0].content;
        const headers = { 'x-csrf-token': csrf };
        const data = {
          name: this.name,
          discarded_at: this.discarded_at,
          template_bookings_attributes: this.templateBookings.map((sc, index) => {
            return {
              id: sc.id === null ? '' : sc.id,
              name: sc.name,
              position: index,
              description: sc.description,
              discarded_at: sc.discarded_at,
              supplier_id: sc.supplier_id,
              task_type: sc.task_type,
              task_id: sc.chosen_sub_task_id ? sc.chosen_sub_task_id : sc.chosen_task_id
            }
          })
        };

        axios({ url, method, data, headers }).then(res => {
          this.id = res.data.id;
          this.templateBookings = res.data.template_bookings || [];
        });
      }
    }
  }
</script>
