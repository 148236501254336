<template>
  <div>
    <div v-show="alert" class="rounded-md bg-green-50 p-4 mb-4">
      <div class="flex justify-center">
        <div class="flex-shrink-0">
          <svg class="h-5 w-5 text-green-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
          </svg>
        </div>
        <div class="ml-3">
          <div class="text-sm leading-5 text-green-700">
            <p>{{ alert }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="flex space-x-4">
      <div class="flex-auto">
        <label class='font-semibold'>Name</label>
        <input type="text" v-model="name" class="bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block w-full appearance-none leading-normal" placeholder='Stage name' required="true">
      </div>
      <div class="flex-auto">
        <label class='font-semibold'>Stage Type</label>
        <select v-model="stage_type" class="bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block w-full appearance-none leading-normal form-select" required="true">
          <option :value="''">Nominate A Stage Type</option>
          <option v-for="stage in workflow_types" :key="stage.value" :value="stage.value">
            {{ stage.name }}
          </option>
        </select>
      </div>
      <div class="flex-1">
        <label class='font-semibold'>Position</label>
        <input v-model="position" type="number" step="1" min="0" class="bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block w-full appearance-none leading-normal" required="true">
      </div>
    </div>
    <div class="flex justify-between items-center mt-4 mb-2">
      <h4 class="font-bold text-lg text-gray-800">Default Learning Templates For Stage</h4>
      <a @click.prevent="addCentre" class="bg-brand-dark hover:bg-brand-light text-white font-bold py-2 px-4 rounded">Add Learning Template</a>
    </div>
    <div class="mb-4">
      <draggable v-model="learning_centres" @start="drag=true" @end="drag=false;" class="flex flex-col space-y-2">
        <div v-for="(centre, index) in learning_centres" :key="centre.id" class="p-3 bg-gray-50 rounded flex flex-col space-y-4 border border-gray-200 selection-option" :class="[centre._destroy ? 'bg-red-100' : '']">
          <div class="flex items-center space-x-4 space-between">
            <div class="flex-1">
              <label class='font-semibold'>Learning Template</label>
              <select v-model="centre.learning_centre_id" required="true" class="bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block w-full appearance-none leading-normal form-select">
                <option :value="null">Nominate the learning template</option>
                <option v-for="learningCentre in all_learning_centres" :key="learningCentre.id" :value="learningCentre.id">
                  {{ learningCentre.name }}
                </option>
              </select>
            </div>
            <a @click.prevent="toggleDelete(index)" class="text-red-500 cursor-pointer">
              <svg v-if="centre._destroy" class="h-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 10h10a8 8 0 018 8v2M3 10l6 6m-6-6l6-6"></path></svg>
              <svg v-else class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path></svg>
            </a>
          </div>
        </div>
      </draggable>
    </div>
    <div class="mt-4 flex justify-between">
      <a href="/staff/workflow_stages" class="text-blue-500">
        Back to all Workflow Stages
      </a>
      <a v-if="this.id != ''" @click.prevent="updateWorkflow" class="bg-brand-dark hover:bg-brand-light text-white font-bold py-2 px-4 rounded cursor-pointer">
        Update Workflow Stage
      </a>

      <a v-else @click.prevent="createWorkflow" class="bg-brand-dark hover:bg-brand-light text-white font-bold py-2 px-4 rounded cursor-pointer">
        Save Workflow Stage
      </a>

    </div>
  </div>
</template>
<script>
  import Vue from 'vue';
  import draggable from 'vuedraggable';
  import axios from 'axios';

  export default {
    components: { draggable },
    data: function() {
      return {
        alert: null,
        id: '',
        name: '',
        stage_type: '',
        workflow_types: [],
        position: '',
        learning_centres: [],
        all_learning_centres: []
      }
    },
    mounted() {
      const idEl = document.getElementById('workflow-stage');

      // Check if New or Edit
      if( idEl === null) {
        this.loadNewWorkflow();
      } else {
        this.id = idEl.dataset.id;
        this.loadEditWorkflow();
      }


    },
    methods: {
      loadNewWorkflow() {
        const url = `/staff/workflow_stages/new.json`;

        axios.get(url).then(({ data }) => {
          this.workflow_types = data.workflow_types;
          this.position = data.position;
        });
      },

      loadEditWorkflow() {
        const url = `/staff/workflow_stages/${this.id}.json`;
        
        axios.get(url).then(({ data }) => {
          this.name = data.name;
          this.stage_type = data.stage_type;
          this.position = data.position;
          this.learning_centres = data.workflow_stage_learning_centres;
          this.all_learning_centres = data.learning_centres;
          this.workflow_types = data.workflow_types;
        });
      },
      addCentre() {
        this.learning_centres = [
          ...this.learning_centres,
          {
            id: null,
            learning_centre_id: null,
            _destroy: false
          }
        ];
      },
      toggleDelete(index) {
        Vue.set(this.learning_centres[index], '_destroy', !this.learning_centres[index]._destroy);
      },

      createWorkflow () {
        const url = `/staff/workflow_stages.json`;
        const csrf = document.getElementsByName('csrf-token')[0].content;
        const headers = { 'x-csrf-token': csrf };
        const data = {
          name: this.name,
          stage_type: this.stage_type,
          position: this.position,
          workflow_stage_learning_centres_attributes: this.learning_centres.filter(lc => lc.learning_centre_id).map((t, index) => {
            return {
              id: t.id,
              learning_centre_id: t.learning_centre_id,
              position: index,
              _destroy: t._destroy
            }
          })
        };

        axios.post(url, data, { headers }).then(({ data }) => {
          window.location = data.redirect_url
          this.alert = 'Workflow stage created successfully';
        });


      },

      updateWorkflow() {
        const url = `/staff/workflow_stages/${this.id}.json`;
        const csrf = document.getElementsByName('csrf-token')[0].content;
        const headers = { 'x-csrf-token': csrf };
        const data = {
          name: this.name,
          stage_type: this.stage_type,
          position: this.position,
          workflow_stage_learning_centres_attributes: this.learning_centres.filter(lc => lc.learning_centre_id).map((t, index) => {
            return {
              id: t.id,
              learning_centre_id: t.learning_centre_id,
              position: index,
              _destroy: t._destroy
            }
          })
        };
        
        axios.patch(url, data, { headers }).then(({ data }) => {
          this.name = data.name;
          this.stage_type = data.stage_type;
          this.position = data.position;
          this.learning_centres = data.workflow_stage_learning_centres;
          this.alert = 'Workflow stage updated successfully';
        });
      }
    }
  }
</script>