<template>
  <div class="w-full bg-white rounded-md shadow divide-y divide-gray-200">
    <div class="border-b-gray-200 flex">
      <div class="p-3 text-left sticky top-0 font-semibold text-gray-500 bg-gray-200 p-0 rounded-tl-md w-1/2">Selection</div>
      <div class="p-3 text-left sticky top-0 font-semibold text-gray-500 bg-gray-200 p-0 w-1/4">Options Count</div>
      <div class="p-3 sticky top-0 font-semibold text-gray-500 bg-gray-200 p-0 rounded-tr-md w-1/4"></div>
    </div>
    <div class="divide-y divide-gray-200">
      <draggable v-model="selections" @start="drag=true" @end="drag=false; saveSelections()" class="divide-y divide-gray-200">
        <div v-for="s in selections" :key="s.id" class="flex">
          <div class="p-3 whitespace-no-wrap w-1/2">
            <a :href="`/staff/selections/${s.id}/edit`" class="font-semibold text-gray-800">{{ s.name }}</a>
          </div>
          <div class="p-3 whitespace-no-wrap w-1/4">
            <a :href="`/staff/selections/${s.id}/edit`" class="font-semibold text-gray-800">{{ s.options_count }}</a>
          </div>
          <div class="p-3 whitespace-no-wrap text-gray-500 w-1/4">
            <a v-if="archived"  :href="`/staff/selections/${s.id}/unarchive`" data-method="patch" data-confirm="Are you sure you want to restore this selection?">Restore</a>
            <a v-else :href="`/staff/selections/${s.id}`" data-method="delete" data-confirm="Are you sure you want to archive this selection?">Archive</a>
          </div>
        </div>
      </draggable>
    </div>
  </div>
</template>
<script>
  import draggable from 'vuedraggable';
  import axios from 'axios';

  export default {
    components: { draggable },
    data: function() {
      return {
        selection_room_id: '',
        selections: [],
        archived: false
      }
    },
    mounted() {
      this.selection_room_id = document.getElementById('selection-room-id').dataset.id;
      this.loadSelections();
    },
    methods: {
      loadSelections() {
        const params = Object.fromEntries(new URLSearchParams(location.search));
        let url = `/staff/selection_rooms/${this.selection_room_id}.json`;

        if (params.archived) {
          this.archived = true;
          url = `${url}?archived=true`;
        }
        
        axios.get(url).then(res => {
          this.selections = res.data.selections;
        });
      },
      saveSelections() {
        if (this.archived) { return true };
        const url = `/staff/selection_rooms/${this.selection_room_id}/reorder.json${this.archived ? '&archived=true' : ''}`;
        const csrf = document.getElementsByName('csrf-token')[0].content;
        const headers = { 'x-csrf-token': csrf };
        const data = {
          selections: this.selections.map((s, index) => {
            return {
              id: s.id,
              position: index,
            }
          })
        };
        
        axios.patch(url, data, { headers }).then(res => {
          this.selections = res.data.selections;
        });
      }
    }
  }
</script>