import { debounce } from './utilities';
import axios from 'axios';

document.addEventListener('turbo:load', function() {
  const projectsIndex = document.getElementById('projects-index');

  const filterToQueryString = (filterObj, overrides) => {
    const copy = Object.assign(filterObj, overrides || {}, );
    const searchParams = new URLSearchParams();

    Object.entries(copy).forEach(([key, value]) => {
      if (Array.isArray(value) && value.length) {
        value.forEach(v => searchParams.append(key, v));
      } else if (value) {
        searchParams.set(key, value)
      }
    });

    searchParams.sort()
    return searchParams.toString()
  }

  const getFilters = () => {
    let filters = {};
    let permittedFilters = ['lead_status', 'name', 'account_user_id', 'new_leads', 'order', 'archived'];
    var url = new URL(window.location);
    var searchParams = new URLSearchParams(url.search);

    permittedFilters.forEach(f => {
      const activeFilter = searchParams.get(f);

      if (activeFilter) filters[f] = activeFilter;
    });

    return filters;
  }

  if (projectsIndex) {
    // Archive selected
    let selected = false;
    const selectAllOpportunities = document.getElementById('select-all-opportunities');
    const archiveLink = document.getElementById('archive-opportunities');
    const unarchiveLink = document.getElementById('unarchive-opportunities');

    selectAllOpportunities.addEventListener('click', function() {
      const checkboxInputs = document.getElementsByName('opportunity_select');
      checkboxInputs.forEach(input => input.checked = !selected);

      selected = !selected;
    });
    
    if (archiveLink) {
      archiveLink.addEventListener('click', function(e) {
        e.preventDefault();

        if (confirm('Are you sure you want to archive these projects?')) {
          e.preventDefault();

          const checkboxInputs = document.querySelectorAll('input[name=opportunity_select]:checked');
          const selectedIds = [...checkboxInputs].map(input => {
            return input.dataset.id;
          });
          const csrf = document.getElementsByName('csrf-token')[0].content;
          const headers = { 'x-csrf-token': csrf };
          const data = { opportunity_ids: selectedIds };
          
          axios.patch(
            `/staff/opportunities/archive_all`,
            data,
            { headers }
          ).then(res => {
            const location = `${window.location.origin}${window.location.pathname}`;
            Turbo.visit(location, { action: 'replace' });
          });
        }
      });
    }

    if (unarchiveLink) {
      unarchiveLink.addEventListener('click', function(e) {
        e.preventDefault();

        if (confirm('Are you sure you want to restore these projects?')) {
          e.preventDefault();

          const checkboxInputs = document.querySelectorAll('input[name=opportunity_select]:checked');
          const selectedIds = [...checkboxInputs].map(input => {
            return input.dataset.id;
          });
          const csrf = document.getElementsByName('csrf-token')[0].content;
          const headers = { 'x-csrf-token': csrf };
          const data = { opportunity_ids: selectedIds };
          
          axios.patch(
            `/staff/opportunities/unarchive_all`,
            data,
            { headers }
          ).then(res => {
            const location = `${window.location.origin}${window.location.pathname}`;
            Turbo.visit(location, { action: 'replace' });
          });
        }
      });
    }

    // Filters
    const searchInput = document.getElementById('opportunity_search');
    const salesPerson = document.getElementById('project_manager_au_id');
    const workflowStage = document.getElementById('workflow_stage_id');
    const showCompleted = document.getElementById('show_completed');

    // Table heading orders
    const sorts = ['name', 'created_at', 'suburb', 'project_manager_au_id', 'project_stage'];

    sorts.forEach(s => {
      const el = document.getElementById(`${s}_sort`);
      
      el.addEventListener('click', function(e) {
        const filters = getFilters();
        const url = new URL(window.location);
        const searchParams = new URLSearchParams(url.search);
        let order = searchParams.get('order');
        let orderParts = [];
        if (order) {
          orderParts = order.split('_');
        }
        const orderDirection = order && orderParts[orderParts.length - 1] === 'asc' ? 'desc' : 'asc';
        let newSearch = { order: `${s}_${orderDirection}` };

        var location = `${window.location.origin}${window.location.pathname}?${filterToQueryString(filters, newSearch)}`;
        order === 'asc' ? order = 'desc' : order = 'asc';
        Turbo.visit(location, { action: 'replace' });
      });
    })

    searchInput.addEventListener('keyup', debounce(function(e) {
      var filters = getFilters();
      var location = `${window.location.origin}${window.location.pathname}?${filterToQueryString(filters, { name: e.target.value })}`;
    
      Turbo.visit(location, { action: 'replace' });
    }, 600));

    salesPerson.addEventListener('change', function(e) {
      var filters = getFilters();
      var location = `${window.location.origin}${window.location.pathname}?${filterToQueryString(filters, { project_manager_au_id: e.target.value })}`;
    
      Turbo.visit(location, { action: 'replace' });
    });

    workflowStage.addEventListener('change', function(e) {
      var filters = getFilters();
      var location = `${window.location.origin}${window.location.pathname}?${filterToQueryString(filters, { workflow_stage_id: e.target.value })}`;

      Turbo.visit(location, { action: 'replace' });
    });

    showCompleted.addEventListener('change', function(e) {
      let showValue = 'true';
      if (e.target.value == 'true') {
        showValue = 'false'
      }

      var filters = getFilters();
      var location = `${window.location.origin}${window.location.pathname}?${filterToQueryString(filters, { show_completed: showValue })}`;

      Turbo.visit(location, { action: 'replace' });
    });
  }
});
