import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["byDateCheckbox", "dateModule", "dayModule", "submit", "daySelector", "dayInput", "dateInput"]

    defaultUrl = ""
    connect() {
        this.defaultUrl = this.submitTarget.dataset.url;
        console.log(this.daySelectorTarget.selectedIndex);
    }

    toggleByDate(event) {
        const el = event.target;
        if(el.checked) {
            this.dateModuleTarget.classList.remove('hidden');
            this.dayModuleTarget.classList.add('hidden');
        } else {
            this.dateModuleTarget.classList.add('hidden');
            this.dayModuleTarget.classList.remove('hidden');
        }

        this.updateSubmitUrl();
    }

    // ADDS PARAMS TO SUBMIT URL FOR HOW MANY DAYS TO MOVE
    updateSubmitUrl() {
        let days = 0;
        let date = null;

        if(this.byDateCheckboxTarget.checked) {
            // DATE INPUT
            if(this.dateInputTarget.value == '') { this.disableSubmit(); return; }
            this.enableSubmit();
            this.submitTarget.dataset.url = `${this.defaultUrl}?date=${this.dateInputTarget.value}` //append DATE
        } else {
            // DAY INPUT
            if(this.dayInputTarget.value == '' || this.dayInputTarget.value == 0) { this.disableSubmit(); return; }
            this.enableSubmit();

            if(this.daySelectorTarget.selectedIndex == 0) {
                //later
                days = this.dayInputTarget.value;
            } else {
                //earlier
                days = `-${this.dayInputTarget.value}`;
            }
            this.submitTarget.dataset.url = `${this.defaultUrl}?days=${days}` //append DAYS
        }
    }

    enableSubmit(event) {
        this.submitTarget.disabled = false;
    }

    disableSubmit(event) {
        this.submitTarget.disabled = true;
    }
}
