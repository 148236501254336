<template>
  <FullCalendar
    ref="fullCalendar"
    :options="calendarOptions"
  />
</template>
<script>

import FullCalendar from '@fullcalendar/vue';
import momentPlugin, { toMoment } from '@fullcalendar/moment';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';

export default {
  components: {
    FullCalendar
  },
  data() {
    const opportunityId = document.getElementById('opportunity-id');

    return {
      opportunityId: opportunityId.dataset.opportunityId,
      calendarOptions: {
        plugins: [ listPlugin, interactionPlugin, momentPlugin ],
        initialView: 'rollingYear',
        events: `/staff/opportunities/${opportunityId.dataset.opportunityId}/bookings.json`,
        eventClick: this.handleEventClick,
        eventDidMount: this.eventContent,
        headerToolbar: {
          start: 'title',
          center: '',
          end: 'rollingYear listDay listMonth prev,next'
        },
        views: {
          rollingYear: {
            type: 'list',
            duration: { days: 365 },
            buttonText: 'Rolling Year View'
          },
          listDay: {
            type: 'listDay',
            buttonText: 'Today'
          },
          listMonth: {
            type: 'listMonth',
            buttonText: 'Month View'
          }
        }
      }
    }
  },
  methods: {
    handleEventClick(info) {
      const eventInfo = info.event._def;

      let url = `/staff/opportunities/${this.opportunityId}/bookings/${eventInfo.publicId}/edit`

      fetch(url)
          .then(r => r.text())
          .then(html => Turbo.renderStreamMessage(html))
    },
    eventContent: function(info) {
      let background, icon;

      info.el.classList.add('cursor-pointer');
      info.el.classList.add('hover:text-gray-800');
      info.el.setAttribute('id',`calendar_booking_${info.event._def.publicId}`);


      switch(info.event._def.extendedProps.status) {
        case 'confirmed':
          background = 'bg-green-100';
          icon = '<span class="bg-green-500 h-6 w-6 rounded-xl flex items-center justify-center mr-2"><svg class="h-5 w-5 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg></span>';
          break;
        case 'complete':
          info.el.classList.add('text-white');
          background = 'bg-brand-dark';
          icon = '<span class="bg-blue-500 h-6 w-6 rounded-xl flex items-center justify-center mr-3"><svg class="text-white h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg></span>';
          break;
        default: // Pending
          background = 'bg-red-100';
          icon = '<span class="bg-red-400 h-6 w-6 rounded-xl flex items-center justify-center mr-2"><svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg></span>';
      }

      info.el.getElementsByClassName('fc-list-event-graphic')[0].innerHTML = icon;
      info.el.classList.add(background);
    },
  }
}
</script>
