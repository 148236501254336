<template>
  <table class="table-auto w-full table-collapse bg-white rounded-md shadow divide-y divide-gray-200">
    <thead>
      <tr class="border-b-gray-200">
        <th class="p-3 text-left sticky top-0 font-semibold text-gray-500 bg-gray-200 p-0 rounded-tl-md">Name</th>
        <th class="p-3 sticky top-0 font-semibold text-gray-500 bg-gray-200 p-0 rounded-tr-md"></th>
      </tr>
    </thead>
    <tbody class="divide-y divide-gray-200">
      <draggable v-model="categories" @start="drag=true" @end="drag=false;saveStages()" class="flex flex-col">
        <tr v-for="cat in categories" :key="cat.id" class="flex flex-auto justify-between">
          <td class="p-3 whitespace-no-wrap">
            <a :href="`/staff/client_document_categories/${cat.id}/edit`" class="font-semibold text-gray-800">{{ cat.name }}</a>
          </td>
          <td class="p-3 whitespace-no-wrap text-gray-500">
            <a :href="`/staff/client_document_categories/${cat.id}`" data-method="delete" data-confirm="Are you sure you want to archive this client document category?">Archive</a>
          </td>
        </tr>
      </draggable>
    </tbody>
  </table>
</template>
<script>
   import draggable from 'vuedraggable';
   import axios from 'axios';

   export default {
     components: { draggable },
     data: function() {
       return {
         categories: []
       }
     },
     mounted() {
       this.loadStages();
     },
     methods: {
       loadStages() {
         const url = '/staff/client_document_categories.json';

         axios.get(url).then(res => {
           this.categories = res.data.categories;
         });
       },
       saveStages() {
         const url = `/staff/client_document_categories/reorder.json`;
         const csrf = document.getElementsByName('csrf-token')[0].content;
         const headers = { 'x-csrf-token': csrf };
         const data = {
           client_document_categories: this.categories.map((t, index) => {
             return {
               id: t.id,
               position: index + 1,
             }
           })
         };

         axios.patch(url, data, { headers }).then(res => {
           this.categories = res.data.categories;
         });
       }
     }
   }
 </script>
