<template>
  <div>
    <div class="flex space-x-4">
      <div class="flex-auto">
        <label class='font-semibold text-gray-800'>Category Name*</label>
        <input type="text" :value="name" @change="name = $event.target.value; saveLearningCategory()" class="bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block w-full appearance-none leading-normal" placeholder='Learning category name'>
      </div>
      <div class="flex-auto">
        <label class='font-semibold text-gray-800'>Available For</label>
        <select :value="category_type" @change="category_type = $event.target.value; saveLearningCategory()" class="bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block w-full appearance-none leading-normal form-select">
          <option value="all_users">All</option>
          <option value="clients_only">Clients Only</option>
          <option value="staff_only">Staff Only</option>
        </select>
      </div>
    </div>
    <div class="flex items-center justify-between my-4">
      <h3 class="font-bold text-xl text-gray-800">Learning Steps</h3>
      <a :href="`/staff/learning_categories/${this.id}/learning_steps/new`" class="inline-flex items-center justify-center px-3 py-1 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-brand-dark hover:bg-brand-light focus:outline-none focus:shadow-outline transition duration-150 ease-in-out">
        <svg class="w-6 h-6 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path></svg>
        Add New Learning Step
      </a>
    </div>
    <div>
      <table class="table-auto w-full table-collapse bg-white rounded-md shadow divide-y divide-gray-200">
        <thead>
          <tr class="border-b-gray-200">
            <th class="p-3 text-left sticky top-0 font-semibold text-gray-500 bg-gray-200 p-0 rounded-tl-md">Name</th>
            <th class="p-3 sticky top-0 font-semibold text-gray-500 bg-gray-200 p-0 rounded-tr-md"></th>
          </tr>
        </thead>
        <tbody class="divide-y divide-gray-200">
          <draggable v-model="learning_steps" @start="drag=true" @end="drag=false; saveLearningCategory()" class="flex flex-col">
            <tr v-for="step in shownSteps" :key="step.id" class="flex flex-auto justify-between">
              <td class="p-3 whitespace-no-wrap">
                <a :href="`/staff/learning_steps/${step.id}/edit`" class="font-semibold text-gray-800">{{ step.name }}</a>
              </td>
              <td class="p-3 whitespace-no-wrap text-gray-500">
                <a v-if="step.discarded_at" :href="`/staff/learning_steps/${step.id}/unarchive`" data-method="patch" data-confirm="Are you sure you want to restore this learning step?">Restore</a>
                <a v-else :href="`/staff/learning_steps/${step.id}`" data-method="delete" data-confirm="Are you sure you want to archive this learning step?">Archive</a>
              </td>
            </tr>
          </draggable>
        </tbody>
      </table>
      <div class="flex items-center justify-center mt-8 text-blue-500">
        <a href="#" @click.prevent="showKept = !showKept">Toggle Archived Learning Steps</a>
      </div>
    </div>
  </div>
</template>
<script>
  import draggable from 'vuedraggable';
  import axios from 'axios';

  export default {
    components: { draggable },
    data: function() {
      return {
        id: '',
        name: '',
        category_type: 'all_users',
        discarded_at: null,
        learning_steps: [],
        showKept: true
      }
    },
    computed: {
      shownSteps() {
        return this.showKept ? this.learning_steps.filter(ls => !ls.discarded_at) : this.learning_steps.filter(ls => ls.discarded_at);
      }
    },
    mounted() {
      this.id = document.getElementById('learning-category-id') ?
          document.getElementById('learning-category-id').dataset.id : null;
      this.loadTemplate();
    },
    methods: {
      loadTemplate() {
        let url = `/staff/learning_categories/${this.id}/learning_steps.json`;

        axios.get(url)
             .then(res => {
               this.name = res.data.name;
               this.discarded_at = res.data.discarded_at;
               this.category_type = res.data.category_type;
               this.learning_steps = res.data.learning_steps;
             });
      },
      saveLearningCategory() {
        let url = `/staff/learning_categories/${this.id}.json`;
        let method = 'PATCH';

        const csrf = document.getElementsByName('csrf-token')[0].content;
        const headers = { 'x-csrf-token': csrf };
        const data = {
          learning_category: {
            name: this.name,
            discarded_at: this.discarded_at,
            category_type: this.category_type,
            learning_steps_attributes: [
              ...this.learning_steps.filter(ls => !ls.discarded_at).map((ls, index) => {
                return {
                  id: ls.id === null ? '' : ls.id,
                  name: ls.name,
                  discarded_at: ls.discarded_at,
                  position: index
                }
              }),
              ...this.learning_steps.filter(ls => ls.discarded_at).map(ls => {
                return {
                  id: ls.id === null ? '' : ls.id,
                  name: ls.name,
                  discarded_at: ls.discarded_at,
                  position: 0
                }
              })
            ]
          }
        };

        axios({ url, method, data, headers }).then(res => {
          this.id = res.data.id;
          this.name = res.data.name;
          this.category_type = res.data.category_type;
          this.learning_steps = res.data.learning_steps;
        }).catch(error => {
          this.errors = error.response.data;
        });
      }
    }
  }
</script>
