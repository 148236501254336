import { Controller } from "@hotwired/stimulus";
import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import moment from "moment";

export default class extends Controller {
  static targets = ["calendar", "projectsOnly"];

  calendar = null;

  types = [];
  user_ids = [];
  builder_ids = [];
  opportunity_ids = [];

  filter(e) {
      //add all checked values to arrays
      this.types = [].filter.call(document.getElementsByName('types[]'), function(c) {
        return c.checked;
      }).map(function(c) {
        return c.value;
      });

      this.user_ids = [].filter.call(document.getElementsByName('staff'), function(c) {
        return c.checked;
      }).map(function(c) {
        return c.value;
      });

      this.builder_ids = [].filter.call(document.getElementsByName('builder'), function(c) {
        return c.checked;
      }).map(function(c) {
        return c.value;
      });

      this.opportunity_ids = [].filter.call(document.getElementsByName('opportunity'), function(c) {
          return c.checked;
      }).map(function(c) {
          return c.value;
      });

      var eventSource = [];
      eventSource = this.calendar.getEventSources();
      eventSource.forEach(e => e.remove());

      var url = '/staff/calendar/get_data.json?'
      url += this.types.length > 0 ? `types=${this.types}&` : '';
      url += this.user_ids.length > 0 ? `account_user_ids=${this.user_ids}&` : '';
      url += this.builder_ids.length > 0 ? `account_builder_ids=${this.builder_ids}&` : '';
      url += this.opportunity_ids.length > 0 ? `opportunity_ids=${this.opportunity_ids}&` : '';
      url += this.projectsOnlyTarget.checked ? `projects_only=true` : '';

      this.calendar.addEventSource(url); // adding an event source auto re-fetches
      this.calendar.render();
  }

  connect() {
    let calendar = new Calendar(this.calendarTarget, {
      plugins: [dayGridPlugin,timeGridPlugin,interactionPlugin],
      headerToolbar: {
        left: 'prev,next today',
        center: 'title',
        right: 'dayGridMonth,dayGridWeek,dayGridDay'
      },
      initialView: 'dayGridMonth',
      dateClick: this.handleDateClick,
      eventContent: this.handleEventContent
    });

    this.calendar = calendar;
    this.filter();
  }

  disconnect() {
      this.calendar.destroy();
  }

    handleEventContent = function(args) {
    let elem = document.createElement('div');
    elem.classList.add('w-full');
    elem.classList.add('calendar-event');
    const startDate = moment(args.event.start).format('YYYY-MM-DD');

    const start = moment(args.event.start).format('h:mma');
    const end = moment(args.event.end).format('h:mma');

    let statusIcon = '';
    let borderColor = 'border-black';

    switch (args.event._def.extendedProps.status) {
        case 'completed':
        case 'complete':
            statusIcon = `<svg class="bg-blue-500 h-4 w-4 rounded-xl flex items-center justify-center text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg>`;
            borderColor = 'border-blue-500';
            break;

        case 'in_progress':
        case 'confirmed':
            statusIcon = `<svg class="bg-green-500 h-4 w-4 rounded-xl flex items-center justify-center text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>`
            borderColor = 'border-green-500';
            break;

        case 'incomplete':
        case 'pending':
            statusIcon = `<svg class="bg-gray-200 text-gray-500 w-4 h-4 rounded-xl flex items-center justify-center" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>`
            borderColor = 'border-gray-500';
            break;
    }

    switch(args.event._def.extendedProps.type)
    {
      case 'event':
        elem.innerHTML = `
            <a href="/staff/events/${args.event.id}" class="flex flex-col bg-brand-light p-1 rounded hover:w-full border-2 border-2 border-blue-500">
              <div class="flex text-xs items-center overflow-x-hidden">
                <span class="text-gray-500 text-xs mr-1">${start}</span>
                <span class="font-semibold text-gray-800">${args.event.title}</div>
              </div>
              <div class="text-xs calendar-event__hidden">
                <p class="text-gray-600 text-xs mt-1" style="white-space: pre-wrap;">${args.event._def.extendedProps.description}</p>
                <p class="text-gray-600 text-xs mt-1">
                <span class="font-semibold text-gray-800">Time: </span>${start} - ${end}<br />
                  <span class="font-semibold text-gray-800">Host: </span>${args.event._def.extendedProps.created_by ? args.event._def.extendedProps.created_by : 'None'}<br />
                  <span class="font-semibold text-gray-800">Guests: </span>${args.event._def.extendedProps.users.length > 0 ? args.event._def.extendedProps.users.map(u => u.name).join(', ') : 'None'}<br />
                  <span class="font-semibold text-gray-800">Location: </span>${args.event._def.extendedProps.location ? args.event._def.extendedProps.location : 'None Set'}<br />
                </p>
              </div>
            </div>
          `
        break;
      case 'booking':
        elem.innerHTML = `
            <a href="/staff/opportunities/${args.event._def.extendedProps.opportunity_id}/bookings?date=${startDate}" class="flex flex-col bg-green-200 p-1 rounded hover:w-full border-2 ${borderColor}">
                          <div class="text-xs items-center overflow-x-hidden">
                  <div class="flex">
                    <div class="text-gray-500 text-xs mr-1">${statusIcon}</div>
                  <span class="text-gray-500 text-xs mr-1">${start}</span>   
              </div>
                <span class="font-semibold text-gray-800">${args.event._def.extendedProps.opportunity_id} | ${args.event.title}</div>
              </div>

              <div class="text-xs calendar-event__hidden">
                <p class="text-gray-600 text-xs mt-1" style="white-space: pre-wrap;">${args.event._def.extendedProps.description}</p>
                <p class="text-gray-600 text-xs mt-1">
                  <span class="font-semibold text-gray-800">Address: </span><span style="white-space: pre-wrap;">${args.event._def.extendedProps.address}</span><br/>
                  <span class="font-semibold text-gray-800">Time: </span>${start} - ${end}<br />
                  <span class="font-semibold text-gray-800">Supplier: </span>${args.event._def.extendedProps.supplier ? args.event._def.extendedProps.supplier : 'None'}<br />
                  <span class="font-semibold text-gray-800">Users: </span>${args.event._def.extendedProps.users.length > 0 ? args.event._def.extendedProps.users.map(u => u.name).join(', ') : 'None'}<br />
                </p>
              </div>
            </div>
          `
        break;
      case 'action_item':
        let url = args.event._def.extendedProps.opportunity_id === null ? url = `/staff/action_items?action_item_id=${args.event.id}` : `/staff/opportunities/${args.event._def.extendedProps.opportunity_id}/action_items?action_item_id=${args.event.id}`;

        elem.innerHTML = `
            <a href= "${url}" class="flex flex-col bg-pink-200 p-1 rounded hover:w-full border-2 ${borderColor}">
              <div class="text-xs items-center overflow-x-hidden">
                  <div class="flex">
                    <div class="text-gray-500 text-xs mr-1">${statusIcon}</div>
                  <span class="text-gray-500 text-xs mr-1">${start}</span>   
              </div>
                <span class="font-semibold text-gray-800">${ args.event._def.extendedProps.opportunity_id? args.event._def.extendedProps.opportunity_id + '|' + args.event.extendedProps.contact_name : 'No Opportunity' }</span>
              </div>
              <div class="text-xs calendar-event__hidden">
                <p class="text-gray-600 text-xs mt-1" style="white-space: pre-wrap;">${args.event._def.extendedProps.description}</p>
                <p class="text-gray-600 text-xs mt-1">
                <span class="font-semibold text-gray-800">Address: </span><span style="white-space: pre-wrap;">${args.event._def.extendedProps.address}</span><br/>
                <span class="font-semibold text-gray-800">Date: </span>${start}<br />
                  <span class="font-semibold text-gray-800">Supplier: </span>${args.event._def.extendedProps.supplier ? args.event._def.extendedProps.supplier : 'None'}<br />
                  <span class="font-semibold text-gray-800">Users: </span>${args.event._def.extendedProps.users.length > 0 ? args.event._def.extendedProps.users.map(u => u.name).join(', ') : 'None'}<br />
                </p>
              </div>
            </div>
          `
        break;
    }

    return { domNodes: [ elem ] };
  }

  handleDateClick = function(e) {
    const path = `/staff/events/new?start=${e.dateStr}`;
    Turbo.visit(path);
  }
}
