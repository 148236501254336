//inherit from Toggle and add open & close icons
import { Toggle } from "tailwindcss-stimulus-components"

export default class ButtonDropdown extends Toggle {
    static targets = ["open", "closed"];

    toggle(event) {
        super.toggle(event);

        if(this.hasOpenTarget && this.hasClosedTarget) {
            if(this.openValue) {
                this.openTarget.classList.remove('hidden');
                this.closedTarget.classList.add('hidden');
            } else {
                this.openTarget.classList.add('hidden');
                this.closedTarget.classList.remove('hidden');
            }
        }
    }
}