import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = [ "input", "filterable", "link" ];

    connect() {
        this.filter()
    }

    filter(event) {
        let filterBy = this.inputTarget.value.toLowerCase();

        this.filterableTargets.forEach((el, i) => {
            let filterableString = el.getAttribute("data-filter-string");

            let hide = filterableString.includes(filterBy);
            el.classList.toggle("hidden", !hide);
        })

        //Append filter_value as query param to links
        this.linkTargets.forEach((element) => {
            let url = new URL(element.href);
            let params = new URLSearchParams(url.search);

            params.set("filter_value", filterBy);
            const new_url = new URL(`${url.origin}${url.pathname}?${params}`);
            element.href = new_url.href;
        });

    }
}