import { debounce } from './utilities';
import axios from 'axios';

document.addEventListener('turbo:load', function() {
  const opportunityIndex = document.getElementById('opportunity-index');
  const opportunityShow = document.getElementById('opportunity-show');

  const filterToQueryString = (filterObj, overrides) => {
    const copy = Object.assign(filterObj, overrides || {}, );
    const searchParams = new URLSearchParams();

    Object.entries(copy).forEach(([key, value]) => {
      if (Array.isArray(value) && value.length) {
        value.forEach(v => searchParams.append(key, v));
      } else if (value) {
        searchParams.set(key, value)
      }
    });

    searchParams.sort()
    return searchParams.toString()
  }

  const getFilters = () => {
    let filters = {};
    let permittedFilters = ['lead_status', 'name', 'account_user_id', 'new_leads', 'order', 'archived'];
    var url = new URL(window.location);
    var searchParams = new URLSearchParams(url.search);

    permittedFilters.forEach(f => {
      const activeFilter = searchParams.get(f);

      if (activeFilter) filters[f] = activeFilter;
    });

    return filters;
  }

  if (opportunityIndex) {
    // Archive selected
    let selected = false;
    const selectAllOpportunities = document.getElementById('select-all-opportunities');
    const archiveLink = document.getElementById('archive-opportunities');
    const unarchiveLink = document.getElementById('unarchive-opportunities');

    selectAllOpportunities.addEventListener('click', function() {
      const checkboxInputs = document.getElementsByName('opportunity_select');
      checkboxInputs.forEach(input => input.checked = !selected);

      selected = !selected;
    });

    if (archiveLink) {
      archiveLink.addEventListener('click', function(e) {
        e.preventDefault();

        if (confirm('Are you sure you want to archive these opportunities?')) {
          e.preventDefault();

          const checkboxInputs = document.querySelectorAll('input[name=opportunity_select]:checked');
          const selectedIds = [...checkboxInputs].map(input => {
            return input.dataset.id;
          });
          const csrf = document.getElementsByName('csrf-token')[0].content;
          const headers = { 'x-csrf-token': csrf };
          const data = { opportunity_ids: selectedIds };

          axios.patch(
            `/staff/opportunities/archive_all`,
            data,
            { headers }
          ).then(res => {
            const location = `${window.location.origin}${window.location.pathname}`;
            Turbo.visit(location, { action: 'replace' });
          });
        }
      });
    }

    if (unarchiveLink) {
      unarchiveLink.addEventListener('click', function(e) {
        e.preventDefault();

        if (confirm('Are you sure you want to restore these opportunities?')) {
          e.preventDefault();

          const checkboxInputs = document.querySelectorAll('input[name=opportunity_select]:checked');
          const selectedIds = [...checkboxInputs].map(input => {
            return input.dataset.id;
          });
          const csrf = document.getElementsByName('csrf-token')[0].content;
          const headers = { 'x-csrf-token': csrf };
          const data = { opportunity_ids: selectedIds };

          axios.patch(
            `/staff/opportunities/unarchive_all`,
            data,
            { headers }
          ).then(res => {
            const location = `${window.location.origin}${window.location.pathname}`;
            Turbo.visit(location, { action: 'replace' });
          });
        }
      });
    }

    // Filters
    const searchInput = document.getElementById('opportunity_search');
    const newLeads = document.getElementById('new_leads');
    const leadStatus = document.getElementById('lead_status');
    const salesPerson = document.getElementById('account_user_id');

    // Table heading orders
    const sorts = ['name', 'lead_status', 'created_at', 'suburb', 'account_user_id'];

    sorts.forEach(s => {
      const el = document.getElementById(`${s}_sort`);

      el.addEventListener('click', function(e) {
        const filters = getFilters();
        const url = new URL(window.location);
        const searchParams = new URLSearchParams(url.search);
        let order = searchParams.get('order');
        let orderParts = [];
        if (order) {
          orderParts = order.split('_');
        }
        const orderDirection = order && orderParts[orderParts.length - 1] === 'asc' ? 'desc' : 'asc';
        let newSearch = { order: `${s}_${orderDirection}` };

        var location = `${window.location.origin}${window.location.pathname}?${filterToQueryString(filters, newSearch)}`;
        order === 'asc' ? order = 'desc' : order = 'asc';
        Turbo.visit(location, { action: 'replace' });
      });
    })

    searchInput.addEventListener('keyup', debounce(function(e) {
      var filters = getFilters();
      var location = `${window.location.origin}${window.location.pathname}?${filterToQueryString(filters, { name: e.target.value })}`;

      Turbo.visit(location, { action: 'replace' });
    }, 600));

    newLeads.addEventListener('keyup', debounce(function(e) {
      var filters = getFilters();
      var location = `${window.location.origin}${window.location.pathname}?${filterToQueryString(filters, { new_leads: e.target.value })}`;

      Turbo.visit(location, { action: 'replace' });
    }, 300));

    leadStatus.addEventListener('change', function(e) {
      var filters = getFilters();
      var location = `${window.location.origin}${window.location.pathname}?${filterToQueryString(filters, { lead_status: e.target.value })}`;

      Turbo.visit(location, { action: 'replace' });
    });

    salesPerson.addEventListener('change', function(e) {
      var filters = getFilters();
      var location = `${window.location.origin}${window.location.pathname}?${filterToQueryString(filters, { account_user_id: e.target.value })}`;

      Turbo.visit(location, { action: 'replace' });
    });
  }

  if (opportunityShow) {
    const showContacts = document.getElementById('show-contacts');

    if (showContacts) {
      showContacts.addEventListener('click', function(e) {
        e.preventDefault();

        if (showContacts.dataset.show === 'true') {
          showContacts.textContent = 'Show all contacts';
          showContacts.dataset.show = 'false';
        } else {
          showContacts.textContent = 'Hide additional contacts';
          showContacts.dataset.show = 'true';
        }

        const allContacts = document.querySelectorAll('.contact');

        allContacts.forEach(contact => {
          if (contact.classList.contains('hidden')) {
            contact.classList.remove('hidden');
          } else {
            contact.classList.add('hidden');
          }
        })
      });
    }

    const headerInputs = document.getElementById('opportunity_submit');

    if (headerInputs) {
      const statusInput = document.getElementById('opportunity_lead_status_id');
      const pmInput = document.getElementById('opportunity_project_manager_au_id');
      const confidenceInput = document.getElementById('opportunity_confidence_score_id');
      const workflowStageInput = document.getElementById('opportunity_workflow_stage_id');

      if (statusInput) {
        statusInput.addEventListener('change', submitForm);
      }
      if (confidenceInput) {
        confidenceInput.addEventListener('change', submitForm);
      }
      if (workflowStageInput) {
        workflowStageInput.addEventListener('change', submitForm);
      }
      if (pmInput) {
        pmInput.addEventListener('change', submitForm);
      }

      function submitForm(e) {
        const submitButton = document.getElementById('opportunity_submit');

        submitButton.click();
      }
    }
  }

  const clientPermissionCheckbox = document.getElementById('client_permission');
  const adminApprovalCheckbox = document.getElementById('admin_approval');

  if (clientPermissionCheckbox) {
    clientPermissionCheckbox.addEventListener('change', function () {
      updateClientPermission('client_permission');
    });
  }
  if (adminApprovalCheckbox) {
    adminApprovalCheckbox.addEventListener('change', function (){
      updateClientPermission('admin_approval');
    });
  }
});

function updateClientPermission(elementId) {
  const opportunityId = document.getElementById('opportunity_id').value;
  const clientPermission = document.getElementById(elementId);
  const url = `/staff/opportunities/${opportunityId}/update_client_permission`;
  const csrf = document.getElementsByName('csrf-token')[0].content;
  const headers = { 'x-csrf-token': csrf };

  axios.patch(url, {[clientPermission.name]: clientPermission.checked}, { headers });
}
