// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"

window.Rails = Rails;
Rails.start();

require('fslightbox');
const Trix = require("trix")
require("@rails/actiontext")
// require("turbolinks").start()
import * as ActiveStorage from "@rails/activestorage"
require("channels")
import 'alpine-turbo-drive-adapter'
import 'alpinejs'

ActiveStorage.start();

const axios = require('axios');

import "@hotwired/turbo-rails";

// Vue application imports
import '../components/client_document_order'
import '../components/funnel_order'
import '../components/funnel_stages'
import '../components/client_order'
import '../components/client_stages'
import '../components/opportunity'
import '../components/opp_client_progress'
import '../components/documents'
import '../components/calendar'
import '../components/selections_order'
import '../components/trades'
import '../components/purchase_order'
import '../components/schedule'
import '../components/client_bookings'
import '../components/document_categories'
import '../components/schedule_template'
import '../components/learning_steps'
import '../components/learning_categories'
import '../components/learning_centres'
import '../components/workflow_stage'
import '../components/opp_learning_centre'
import '../components/client_note_types'
import '../components/client_documents'

// Page-specific imports
import '../pages/utilities'
import '../pages/opportunity'
import '../pages/projects'
import '../pages/calendar'
import '../pages/action_items'
import '../pages/chat_rooms'
import '../utilities/add_fields'
import '../pages/selection_room'
import '../pages/opp_booking'
import '../pages/handy_contacts'
import '../pages/sales'
import '../pages/client_documents'
import '../pages/opportunity_bookings'
import '../pages/learning_embed'
import '../pages/opportunity_clients_navbar'
import '../pages/photo_gallery'
import '../pages/mutiple_container_draggable'
import '../pages/user_documents'
import '../pages/docusign_log'
import '../pages/video_modals'

// Stimulus Import
import "controllers"

// Libraries
import '../custom/frappe-gantt'

document.addEventListener("turbo:load", ()=> {
  const chat = document.querySelector("[data-chat-scroll]")

  if (chat) {
    chat.scrollIntoView(false);
  }

  const formSubmission = Object.values(document.getElementsByClassName('submit-form'));
  formSubmission.forEach(input => {
    input.addEventListener('change', e => {
      e.target.form.submit();
    });
  });
});

    Trix.config.textAttributes.red = {
        style: { color: "red" },
      parser: function(element) {
        return element.style.color === "red"
      },
      inheritable: true
     }


    Trix.config.blockAttributes.bookmark = {
      tagName: "h6",
      terminal: true,
      breakOnReturn: true,
      group: false
    }

    Trix.config.blockAttributes.alert = {
        tagName: "h5",
        terminal: true,
        breakOnReturn: true,
        group: false
    }


    document.addEventListener("trix-initialize", function(event) {
        let buttonHTML = '<button type="button" class="trix-button" data-trix-attribute="red">RED</button>';
        let bookmarkButton = '<button type="button" class="trix-button" data-trix-attribute="bookmark">' +
          '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-5 h-5 text-green-500">' +
          '<path stroke-linecap="round" stroke-linejoin="round" d="M15.59 14.37a6 6 0 0 1-5.84 7.38v-4.8m5.84-2.58a14.98 14.98 0 0 0 6.16-12.12A14.98 14.98 0 0 0 9.631 8.41m5.96 5.96a14.926 14.926 0 0 1-5.841 2.58m-.119-8.54a6 6 0 0 0-7.381 5.84h4.8m2.581-5.84a14.927 14.927 0 0 0-2.58 5.84m2.699 2.7c-.103.021-.207.041-.311.06a15.09 15.09 0 0 1-2.448-2.448 14.9 14.9 0 0 1 .06-.312m-2.24 2.39a4.493 4.493 0 0 0-1.757 4.306 4.493 4.493 0 0 0 4.306-1.758M16.5 9a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z" />' +
          '</svg>'+
          '</button>';
        let alertButton = '<button type="button" class="trix-button" data-trix-attribute="alert">' +
            '<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-bulb text-blue-500" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">' +
                '<path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M3 12h1m8 -9v1m8 8h1m-15.4 -6.4l.7 .7m12.1 -.7l-.7 .7" /><path d="M9 16a5 5 0 1 1 6 0a3.5 3.5 0 0 0 -1 3a2 2 0 0 1 -4 0a3.5 3.5 0 0 0 -1 -3" />' +
                '<path d="M9.7 17l4.6 0" />' +
            '</svg>'+
            '</button>';

        event.target.toolbarElement.
        querySelector(".trix-button-group").
        insertAdjacentHTML("beforeend", buttonHTML);

        event.target.toolbarElement.
        querySelectorAll(".trix-button-group")[1].
        insertAdjacentHTML("beforeend", bookmarkButton);

        event.target.toolbarElement.
        querySelectorAll(".trix-button-group")[1].
        insertAdjacentHTML("beforeend", alertButton);
    })


import 'dropzone/dist/dropzone.css';
import '../stylesheets/application.scss'
