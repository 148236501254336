<template>
  <table class="table-auto w-full table-collapse bg-white rounded-md shadow divide-y divide-gray-200">
    <thead>
      <tr class="border-b-gray-200">
        <th class="p-3 text-left sticky top-0 font-semibold text-gray-500 bg-gray-200 p-0 rounded-tl-md">Name</th>
        <th class="p-3 sticky top-0 font-semibold text-gray-500 bg-gray-200 p-0 rounded-tr-md"></th>
      </tr>
    </thead>
    <tbody class="divide-y divide-gray-200">
      <draggable v-model="note_types" @start="drag=true" @end="drag=false;saveTypes()" class="flex flex-col">
        <tr v-for="noteType in note_types" :key="noteType.id" class="flex flex-auto justify-between">
          <td class="p-3 whitespace-no-wrap">
            <a :href="`/staff/funnel_stages/${noteType.id}/edit`" class="font-semibold text-gray-800">{{ noteType.name }}</a>
          </td>
          <td class="p-3 whitespace-no-wrap text-gray-500">
            <a :href="`/staff/funnel_stages/${noteType.id}`" data-method="delete" data-confirm="Are you sure you want to delete this note type?">Delete</a>
          </td>
        </tr>
      </draggable>
    </tbody>
  </table>
</template>
<script>
  import draggable from 'vuedraggable';
  import axios from 'axios';

  export default {
    components: { draggable },
    data: function() {
      return {
        note_types: []
      }
    },
    mounted() {
      this.loadTypes();
    },
    methods: {
      loadTypes() {
        const url = '/staff/client_note_types.json';
        
        axios.get(url).then(res => {
          this.note_types = res.data.note_types;
        });
      },
      saveTypes() {
        const url = `/staff/client_note_types/reorder.json`;
        const csrf = document.getElementsByName('csrf-token')[0].content;
        const headers = { 'x-csrf-token': csrf };
        const data = {
          client_note_types: this.note_types.map((t, index) => {
            return {
              id: t.id,
              position: index,
            }
          })
        };
        
        axios.patch(url, data, { headers }).then(res => {
          this.note_types = res.data.note_types;
        });
      }
    }
  }
</script>