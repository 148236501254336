<template>
  <div>
    <div class="flex justify-between items-center">
      <a v-if="exportAvailable" :href="`/staff/opportunities/${opportunityId}/trades/export`" target="_blank" class="text-blue-500">Export Trade Data</a>
      <p v-else class="text-gray-500">Financial reporting available once at least one quote is approved.</p>
      <div class="flex flex-col">
        <label class="font-semibold text-gray-800">Filter by Quote Status</label>
        <select :value="filter" @change="updateFilter($event.target.value)" class="form-select">
          <option value="">Quote Status</option>
          <option value="na">Not Requested</option>
          <option value="requested">Requested</option>
          <option value="received">Received</option>
          <option value="approved">Approved</option>
          <option value="declined">Declined</option>
        </select>
      </div>
    </div>
    <div>
      <div class="bg-gray-100 rounded mt-4" :class="{ 'bg-green-100': hasSuppliers(trade) && !trade.toggled }" v-for="trade in tradesWithSuppliers" :key="trade.id">
        <div class="rounded text-gray-800 flex items-center justify-between px-4 py-2">
          <div @click.prevent="toggleTrade(trade.id)"  class="cursor-pointer flex items-center justify-start space-x-2 flex-1">
            <h3 class="font-semibold text-xl">{{ trade.name }}</h3>
            <div v-if="hasSuppliers(trade)" class="p-2 rounded-full h-6 w-6 text-xs bg-green-500 text-white flex items-center justify-center">
              {{ trade.activeSuppliers.length }}
            </div>
          </div>
          <div class="flex items-center space-x-2">
            <select :value="trade.selectedSupplier" @change="updateTradeSupplier({ trade: trade, event: $event })" class="form-select">
              <option :value="null">Choose supplier</option>
              <option v-for="supplier in trade.suppliers" :key="`supplier-${supplier.id}`" :value="supplier.id">
                {{ supplier.name }}
              </option>
            </select>
            <a href="#" @click.prevent="addSupplier(trade)" class="text-brand-dark font-semibold">Add Supplier</a>
          </div>
        </div>
          <div v-if="trade.toggled" class="p-4">
            <trade v-for="(s, index) in tradeSuppliers(trade.id)" :key="s.id" :index="index" :supplier="s" :trade-name="trade.name"></trade>
          </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapMutations, mapActions, mapState, mapGetters } from 'vuex';
import trade from './trade';

export default {
  components: { trade },
  data() {
    return {}
  },
  computed: {
    ...mapState([
      'opportunityId',
      'trades',
      'filter'
    ]),
    ...mapGetters([
      'tradeSuppliers',
      'tradesWithSuppliers',
      'exportAvailable'
    ])
  },
  mounted() {
    this.setId();
    this.loadTrades();
    this.loadSuppliers();
  },
  methods: {
    ...mapMutations([
      'setId', 'updateTradeSupplier', 'toggleTrade', 'updateFilter'
    ]),
    ...mapActions([
      'loadTrades', 'addSupplier', 'loadSuppliers'
    ]),
    hasSuppliers(trade) {
      return trade.activeSuppliers.length > 0;
    }
  }
}
</script>