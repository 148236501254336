<template>
  <div>
    <div class="flex items-center justify-between pb-4 mb-12 border-b border-gray-300">
      <div>
        <h1 class="text-3xl font-extrabold text-gray-900 tracking-tight">{{ purchase_order.supplier_name }}</h1>
        <p class="text-gray-600 mb-4">{{ purchase_order.supplier_address }}</p>
        <div class="flex space-x-2 items-center">
          <input type="text" @change="updateOrder('name', $event.target.value)" :value="purchase_order.name" class="input" placeholder="Name or Description">
          <input type="text" @change="updateOrder('address', $event.target.value)" :value="purchase_order.address" class="input" placeholder="Project Address">
          <select @change="updateOrder('doc_type', $event.target.value)" :value="purchase_order.doc_type" class="form-select">
            <option value="purchase_order">Purchase Order</option>
            <option value="quote_request">Quote Request</option>
          </select>
          <input type="date" @change="updateOrder('request_date', $event.target.value)" :value="purchase_order.request_date" class="input">
        </div>
      </div>
      <div class="text-right">
        <a :href="`/staff/purchase_orders/${id}/printable`" target="_blank" class="text-brand-dark font-semibold flex items-center space-x-2">
          <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z"></path></svg>
          <span>Print Version</span>
        </a>
        <a :href="`/staff/purchase_orders/${this.id}`" data-method="DELETE" data-confirm="Are you sure you want to delete this purchase order?" class="text-red-500 font-semibold flex items-center space-x-2 mt-4">
          <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path></svg>
          <span>Delete</span>
        </a>
      </div>
    </div>
    <table class="w-full">
      <thead>
        <tr>
          <th></th>
          <th>Code</th>
          <th>Item Description</th>
          <th>Unit</th>
          <th>Est. Qty</th>
          <th>Est. Unit Price</th>
          <th>Est. Total</th>
          <th>Quote Qty</th>
          <th>Quote Unit Price</th>
          <th>Quote Total</th>
          <th>Actual Qty</th>
          <th>Actual Unit Price</th>
          <th>Actual Total</th>
          <th>Est. minus actual</th>
          <th>Quote minus actual</th>
          <th>Est. minus quote</th>
          <th></th>
        </tr>
      </thead>
      <draggable v-model="purchase_order.line_items" @end="endDrag" tag="tbody" handle=".drag">
        <tr v-for="(item, index) in purchase_order.line_items" :key="`${item.id}-${item.index}`" class="drag">
          <td>
            <span class="text-gray-300">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 10h16M4 14h16M4 18h16" />
              </svg>
            </span>
          </td>
          <td>
            <input type="text" @change="updateLineItem(index, 'item_code', $event.target.value)" :value="item.item_code" class="input full sm:text-sm sm:leading-5">
          </td>
          <td>
            <input type="text" @change="updateLineItem(index, 'label', $event.target.value)" :value="item.label" class="input full sm:text-sm sm:leading-5">
          </td>
          <td>
            <select @change="updateLineItem(index, 'unit', $event.target.value)" :value="item.unit" class="form-select w-full">
              <option value="ea">Ea</option>
              <option value="sqm">Sqm</option>
              <option value="lm">Lm</option>
              <option value="hours">Hours</option>
            </select>
          </td>
          <td>
            <input type="number" step="2" min="0" @change="updateLineItem(index, 'estimate_quantity', $event.target.value)" :value="item.estimate_quantity" class="input full sm:text-sm sm:leading-5">
          </td>
          <td>
            <input type="number" step="2" min="0" @change="updateLineItem(index, 'estimate_unit_price', $event.target.value)" :value="item.estimate_unit_price" class="input full sm:text-sm sm:leading-5">
          </td>
          <td class="text-center">
            <span class="font-bold">${{ estimateTotal(item) }}</span>
          </td>
          <td>
            <input type="number" step="2" min="0" @change="updateLineItem(index, 'quote_quantity', $event.target.value)" :value="item.quote_quantity" class="input full sm:text-sm sm:leading-5">
          </td>
          <td>
            <input type="number" step="2" min="0" @change="updateLineItem(index, 'quote_unit_price', $event.target.value)" :value="item.quote_unit_price" class="input full sm:text-sm sm:leading-5">
          </td>
          <td class="text-center">
            <span class="font-bold">${{ quotedTotal(item) }}</span>
          </td>
          <td>
            <input type="number" step="2" min="0" @change="updateLineItem(index, 'quantity', $event.target.value)" :value="item.quantity" class="input full sm:text-sm sm:leading-5">
          </td>
          <td>
            <input type="number" step="2" min="0" @change="updateLineItem(index, 'unit_price', $event.target.value)" :value="item.unit_price" class="input full sm:text-sm sm:leading-5">
          </td>
          <td class="text-center">
            <span class="font-bold">${{ actualTotal(item) }}</span>
          </td>
          <td class="text-center">
            <span class="font-bold">
              ${{ estMinusActual(item) }}
            </span>
          </td>
          <td class="text-center">
            <span class="font-bold">
              ${{ quoteMinusActual(item) }}
            </span>
          </td>
          <td class="text-center">
            <span class="font-bold">
              ${{ estMinusQuote(item) }}
            </span>
          </td>
          <td>
            <a href="#" @click.prevent="removeLine(item)" class="text-red-500">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
              </svg>
            </a>
          </td>
        </tr>
        <tr>
          <td class="pt-2">
            <p class="text-lg font-semibold text-gray-800 col-span-6">Totals</p>
          </td>
          <td class="pt-2" colspan="3"></td>
          <td class="pt-2">
            <p class="text-lg font-semibold text-center">{{ estimateQuantityTotal }}</p>
          </td>
          <td></td>
          <td class="pt-2">
            <p class="text-lg font-semibold text-center">${{ estimatedTotal }}</p>
          </td>
          <td class="pt-2">
            <p class="text-lg font-semibold text-center">{{ quoteQuantityTotal }}</p>
          </td>
          <td></td>
          <td class="pt-2">
            <p class="text-lg font-semibold text-center">${{ quoteTotal }}</p>
          </td>
          <td class="pt-2">
            <p class="text-lg font-semibold text-center">{{ actualQuantityTotal }}</p>
          </td>
          <td></td>
          <td class="pt-2">
            <p class="text-lg font-semibold text-center">${{ total }}</p>
          </td>
          <td class="pt-2">
            <p class="text-lg font-semibold text-center" v-html="totalEstimateDiff"></p>
          </td>
          <td class="pt-2">
            <p class="text-lg font-semibold text-center" v-html="totalQuoteDiff"></p>
          </td>
           <td class="pt-2">
            <p class="text-lg font-semibold text-center" v-html="totalEstQuoteDiff"></p>
          </td>
        </tr>
      </draggable>
    </table>
    <a href="#" @click.prevent="addLineItem" class="inline-block mt-4 text-brand-dark font-semibold">
      Add Line Item
    </a>
    <h4 class="text-gray-800 font-bold mt-8">Notes</h4>
    <textarea @change="updateOrder('notes', $event.target.value)" :value="purchase_order.notes" rows="4" class="mt-1 p-2 shadow-sm block w-full focus:border-brand-dark sm:text-sm border border-gray-300 rounded-md"></textarea>
  </div>
</template>
<script>
import draggable from 'vuedraggable';
import axios from 'axios';

export default {
  components: { draggable },
  data() {
    return {
      id: null,
      purchase_order: {}
    }
  },
  mounted() {
    this.id = document.getElementById('purchase-order-id').dataset.id;
    this.loadPurchaseOrder();
  },
  computed: {
    documentName() {
      return this.purchase_order.doc_type === 0 ? 'Purchase Order' : 'Quote Request';
    },
    estimateQuantityTotal() {
      if (!this.purchase_order.line_items) {
        return 0;
      }

      return this.purchase_order.line_items.reduce((acc, item) => {
        return acc + parseFloat(item.estimate_quantity || 0);
      }, 0).toFixed(4);
    },
    quoteQuantityTotal() {
      if (!this.purchase_order.line_items) {
        return 0;
      }

      return this.purchase_order.line_items.reduce((acc, item) => {
        return acc + parseFloat(item.quote_quantity || 0);
      }, 0).toFixed(4);
    },
    actualQuantityTotal() {
      if (!this.purchase_order.line_items) {
        return 0;
      }

      return this.purchase_order.line_items.reduce((acc, item) => {
        return acc + parseFloat(item.quantity || 0);
      }, 0).toFixed(4);
    },
    total() {
      if (!this.purchase_order.line_items) {
        return 0;
      }
      return this.purchase_order.line_items.reduce((acc, item) => {
        return acc + this.lineTotal(item);
      }, 0).toFixed(4);
    },
    estimatedTotal() {
      if (!this.purchase_order.line_items) {
        return 0;
      }
      return this.purchase_order.line_items.reduce((acc, item) => {
        const quantity = item.estimate_quantity ? item.estimate_quantity : 1;
        const unit_price = item.estimate_unit_price || 0;
        const estimatedPrice = item.estimate_unit_price ? (unit_price * quantity) : 0;

        return acc + estimatedPrice;
      }, 0).toFixed(4);
    },
    quoteTotal() {
      if (!this.purchase_order.line_items) {
        return 0;
      }
      return this.purchase_order.line_items.reduce((acc, item) => {
        const quantity = item.quote_quantity ? item.quote_quantity : 1;
        const unit_price = item.quote_unit_price || 0;
        const quotedPrice = item.quote_unit_price ? (unit_price * quantity) : 0;

        return acc + quotedPrice;
      }, 0).toFixed(4);
    },
    totalEstimateDiff() {
      if (this.estimatedTotal < this.total) {
        return `<p class='text-lg text-red-500'>$${(this.estimatedTotal - this.total).toFixed(4)}</p>`;
      } else {
        return `<p class='text-lg text-green-500'>$${(this.estimatedTotal - this.total).toFixed(4)}</p>`;
      }
    },  
    totalQuoteDiff() {
      if (this.quoteTotal < this.total) {
        return `<p class='text-lg text-red-500'>$${(this.quoteTotal - this.total).toFixed(4)}</p>`;
      } else {
        return `<p class='text-lg text-green-500'>$${(this.quoteTotal - this.total).toFixed(4)}</p>`;
      }
    },
    totalEstQuoteDiff() {
      if (this.estimatedTotal < this.quoteTotal) {
        return `<p class='text-lg text-red-500'>$${(this.estimatedTotal - this.quoteTotal).toFixed(4)}</p>`;
      } else {
        return `<p class='text-lg text-green-500'>$${(this.estimatedTotal - this.quoteTotal).toFixed(4)}</p>`;
      }
    },
  },
  methods: {
    removeLine(line) {
      if (confirm('Are you sure you want to permanently delete this line item? This cannot be undone.')) {
        line._destroy = 'true';
        this.savePurchaseOrder();
      }
    },
    lineTotal(line) {
      const quantity = line.quantity ? line.quantity : 1;

      if (!line.unit_price) { return 0; }

      return quantity * line.unit_price;
    },
    estimateTotal(line) {
      if (line.estimate_quantity && line.estimate_unit_price) {
        return (line.estimate_quantity * line.estimate_unit_price).toFixed(4);
      } else {
        return null;
      }
    },
    quotedTotal(line) {
      if (line.quote_quantity && line.quote_unit_price) {
        return (line.quote_quantity * line.quote_unit_price).toFixed(4)
      } else {
        return null;
      }
    },
    actualTotal(line) {
      if (line.quantity && line.unit_price) {
        return(line.quantity * line.unit_price).toFixed(4)
      } else {
        return null;
      }
    },
    estMinusActual(line) {
      if (this.estimateTotal(line) && this.actualTotal(line)) {
        return (this.estimateTotal(line) - this.actualTotal(line)).toFixed(4);
      } else {
        return null;
      }
    },
    quoteMinusActual(line) {
      if (this.quotedTotal(line) && this.actualTotal(line)) {
        return (this.quotedTotal(line) - this.actualTotal(line)).toFixed(4);
      } else {
        return null;
      }
    },
    estMinusQuote(line) {
      if (this.estimateTotal(line) && this.quotedTotal(line)) {
        return (this.estimateTotal(line) - this.quotedTotal(line)).toFixed(4);
      } else {
        return null;
      }
    },
    estimateDiff(line) {
      if (this.lineTotal(line) && line.estimated_price) {
        const quantity = line.quantity ? line.quantity : 1;
        const diff = this.lineTotal(line) - (line.estimated_price * quantity);

        if (diff < 0) {
          return `<div class="text-sm flex-1"><p class="font-bold">Est. Diff</p><span class='text-green-500'>$${diff}</span></div>`;
        } else {
          return `<div class="text-sm flex-1"><p class="font-bold">Est. Diff</p><span class='text-red-500'>$${diff}</span></div>`;
        }
      }

      return `<div class="flex-1"></div>`;
    },
    quoteDiff(line) {
      if (this.lineTotal(line) && line.quoted_price) {
        const quantity = line.quantity ? line.quantity : 1;
        const diff = this.lineTotal(line) - (line.quoted_price * quantity);

        if (diff < 0) {
          return `<div class="text-sm flex-1"><p class="font-bold">Quote Diff</p><span class='text-green-500'>$${diff}</span></div>`;
        } else {
          return `<div class="text-sm flex-1"><p class="font-bold">Quote Diff</p><span class='text-red-500'>$${diff}</span></div>`;
        }
      }

      return `<div class="flex-1"></div>`;
    },
    loadPurchaseOrder() {
      const url = `/staff/purchase_orders/${this.id}.json`;

      axios.get(url).then(res => {
        this.purchase_order = res.data;
      });
    },
    addLineItem() {
      this.purchase_order.line_items = [
        ...this.purchase_order.line_items,
        {
          id: null,
          label: '',
          item_code: '',
          quantity: 0,
          unit: 'ea',
          price: 0
        }
      ];

      this.savePurchaseOrder();
    },
    endDrag(event) {
      this.savePurchaseOrder();
    },
    updateOrder(attr, value) {
      this.$set(this.purchase_order, attr, value);
      this.savePurchaseOrder();
    },
    updateLineItem(index, attr, value) {
      let line_items = [...this.purchase_order.line_items];
      line_items[index][attr] = value;

      this.$set(this.purchase_order, 'line_items', line_items);
      this.savePurchaseOrder();
    },
    savePurchaseOrder() {
      const url = `/staff/purchase_orders/${this.id}.json`;

      const data = {
        authenticity_token: document.querySelector('[name="csrf-token"]').getAttribute('content'),
        purchase_order: {
          name: this.purchase_order.name,
          address: this.purchase_order.address,
          notes: this.purchase_order.notes,
          request_date: this.purchase_order.request_date,
          doc_type: this.purchase_order.doc_type,
          line_items_attributes: this.purchase_order.line_items.map((li, index) => {
            return {
              ...li,
              position: index
            }
          })
        }
      }

      axios.patch(url, data).then(res => {
        this.purchase_order = res.data;
      });
    }
  }
}
</script>