import { Controller } from "@hotwired/stimulus"
import {FetchRequest} from "@rails/request.js";
import Gantt from "../custom/frappe-gantt";

let gantt = null;
let tasks = null;
let openTaskIds = [];
let allTaskIds = [];
let dataUrl = '/staff/timeframe_templates/id/get_data';
let updateDatesUrl = '/staff/timeframe_templates/id/update_dates'
let editUrl = '/staff/timeframe_templates/id/edit'

function loadData() {
    const csrf = document.getElementsByName('csrf-token')[0].content;
    fetch(dataUrl, {
        method: 'POST',
        body: JSON.stringify({open_task_ids: openTaskIds}),
        headers: { accept: 'application/json', 'x-csrf-token': csrf, "Content-Type": "application/json"}
    }).then((   response) => response.json())
        .then(data => {

            tasks = data;
            if (gantt === null) {
                createGantt();
            } else {
                gantt.refresh(tasks);
            }
            setLoading(false);
        });
}

function setLoading(b_loading) {
    if(b_loading) {
        document.body.classList.add('loading');
    } else {
        document.body.classList.remove('loading');
    }
}

function daysBetweenDates(start_date, end_date) {
    return Math.floor((end_date - start_date) / 1000 / 60 / 60 / 24);
}

function createGantt() {

    gantt = new Gantt("#gantt", tasks, {
        header_height: 50,
        column_width: 30,
        step: 24,
        view_modes: ['Quarter Day', 'Half Day', 'Day', 'Week', 'Month'],
        bar_height: 20,
        bar_corner_radius: 3,
        arrow_curve: 5,
        padding: 18,
        view_mode: 'Day',
        date_format: 'DD-MM-YYYY',
        language: 'en', // or 'es', 'it', 'ru', 'ptBr', 'fr', 'tr', 'zh', 'de', 'hu'
        custom_popup_html: null,

        on_click: function (task) {
        },
        on_date_change: async function(task, start, end) {
            setLoading(true);
            const request = new FetchRequest("POST", `${updateDatesUrl}?task_id=${task.id}&type=${task.type}&start=${start}&end=${end}&stage_id=${task.stage_id}`,{body: JSON.stringify({open_task_ids: openTaskIds}), responseKind: "turbo-stream"});
            const response = await request.perform();

            loadData();
        },
        on_progress_change: function(task, progress) {
            return;
        },
        on_view_change: function(mode) {
        }
    });

}


export default class extends Controller {

    static values = {id:{ type: String, default: 'hello' }};
    static targets = [ "task" ];

    connect() {
        this.taskTargets.forEach(el => allTaskIds.push(el.id));
        dataUrl = dataUrl.replace('id', this.idValue);
        updateDatesUrl = updateDatesUrl.replace('id', this.idValue);
        editUrl = editUrl.replace('id', this.idValue);
        loadData();

        // MUTATION OBSERVER TO UPDATE GANTT AFTER TURBO-STREAM UPDATE
        const targetNode = document.getElementById('turbo-update');
        const observer = new MutationObserver(() => {
            loadData();
        }).observe(targetNode, { childList: true});
    }

    changeView(event) {
        gantt.change_view_mode(event.target.dataset.view);
    }

    expandStage(event) {
        setLoading(true);
        let taskContainerElement = document.getElementById(`container_${event.currentTarget.id}`);

        if (openTaskIds.includes(event.currentTarget.id)) {
        } else {
            openTaskIds.push(event.currentTarget.id);
            taskContainerElement.classList.remove('hidden');
        }

        loadData();
    }

    toggleStage(event) {
        setLoading(true);
        let stageIndex = openTaskIds.indexOf(event.currentTarget.id);
        let taskContainerElement = document.getElementById(`container_${event.currentTarget.id}`);

        if (openTaskIds.includes(event.currentTarget.id)) {
            openTaskIds.splice(stageIndex, 1);
            taskContainerElement.classList.add('hidden');
            event.currentTarget.innerText = '+' + event.currentTarget.innerText.substring(1)
        } else {
            openTaskIds.push(event.currentTarget.id);
            taskContainerElement.classList.remove('hidden');
            event.currentTarget.innerText = '-' + event.currentTarget.innerText.substring(1)
        }

        loadData();
    }

    expandAll(event) {
        setLoading(true);
        openTaskIds = [...allTaskIds];
        openTaskIds.forEach(id => {
            try {
                let taskName = document.getElementById(`${id}`);
                if(taskName.innerText[0] == '+') { taskName.innerText = '-' + taskName.innerText.substring(1) }
                let taskContainerElement = document.getElementById(`container_${id}`);
                taskContainerElement.classList.remove('hidden');
            } catch(error) { }

        });

        loadData();
    }

    contractAll(event) {
        setLoading(true);
        openTaskIds = [];
        allTaskIds.forEach(id => {
            try {
                let taskName = document.getElementById(`${id}`);
                if(taskName.innerText[0] == '-') { taskName.innerText = '+' + taskName.innerText.substring(1) }
                let taskContainerElement = document.getElementById(`container_${id}`);
                taskContainerElement.classList.add('hidden');
            } catch(error) { }
        });

        loadData();
    }

    async applyBookingTemplate(event) {
        if(!confirm('Are you sure you want to apply these booking templates?')) { return;}
        let url = dataUrl.replace('get_data', 'apply_booking_template');
        setLoading(true);
        const request = new FetchRequest("POST", url + `?schedule_template_id=${event.target.value}`);
        const response = await request.perform().then( json =>{
            setLoading(false);
            window.location.href = editUrl;
        });
    }

    disconnect() {
    }
}
