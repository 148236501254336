<template>
  <FullCalendar ref="fullCalendar" :options="calendarOptions" />
</template>
<script>

import FullCalendar from '@fullcalendar/vue';
import listPlugin from '@fullcalendar/list';
import momentPlugin, { toMoment } from '@fullcalendar/moment';
import interactionPlugin from '@fullcalendar/interaction';

export default {
  components: {
    FullCalendar // make the <FullCalendar> tag available
  },
  data() {
    const opportunityId = document.getElementById('opportunity-id');

    return {
      opportunityId: opportunityId.dataset.opportunityId,
      calendarOptions: {
        plugins: [ listPlugin, interactionPlugin, momentPlugin ],
        initialView: 'rollingYear',
        events: `/client/${opportunityId.dataset.opportunityId}/schedules.json`,
        eventClick: this.handleEventClick,
        eventDidMount: this.eventContent,
        headerToolbar: {
          start: 'title',
          center: '',
          end: 'rollingYear listDay listMonth prev,next'
        },
        views: {
          rollingYear: {
            type: 'list',
            duration: { days: 365 },
            buttonText: 'Rolling Year View'
          },
          listDay: {
            type: 'listDay',
            buttonText: 'Today'
          },
          listMonth: {
            type: 'listMonth',
            buttonText: 'Month View'
          }
        }
      }
    }
  }
}
</script>
