<template>
  <div>
    <div v-for="dc in document_categories" :key="`category-${dc.id}`">
      <div class="flex items-center justify-between mt-4">
        <span class="flex items-center">
          <h2 class="font-bold text-2xl text-gray-800 mr-2">{{ dc.name }}</h2>
          <p class="text-gray-500 text-sm">{{ dc.visibility }} default sharing</p>
        </span>
        <a href="#" @click.prevent="toggleUppy(dc.id)" data-behaviour="uppy-trigger">Upload to section</a>
      </div>
      <uppy-dashboard
        :uppy="uppy" 
        v-if="uppyOpen && activeCategoryId === dc.id" 
        :props="{
          proudlyDisplayPoweredByUppy: false,
          width: '100%',
          height: 300
        }"
      />
      <div class="grid grid-cols-5 gap-4 mt-4">
        <document v-for="doc in dc.documents"
                  :key="`document-${doc.id}`"
                  :document="doc"
                  :document_type="dc.document_type"
                  :updateDocument="updateDocument">
        </document>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import Uppy from '@uppy/core';
import Document from './document';
import UppyDashboard from './uppy_dashboard';
const ActiveStorageUpload = require('@excid3/uppy-activestorage-upload');

// import UppyDragDrop from './uppy_drag_drop'; 

export default {
  components: { Document, UppyDashboard },
  data() {
    return {
      document_categories: [],
      opportunity_id: '',
      uppyOpen: false,
      activeCategoryId: '',
      csrfToken: ''
    }
  },
  mounted() {
    this.opportunity_id = document.getElementById('opportunity-id').dataset.id;
    this.csrfToken = document.querySelector('[name="csrf-token"]').getAttribute('content');
    this.loadDocuments();
  },
  computed: {
    uppy: function() {
      const uppy = new Uppy({
        id: 'document-upload',
        autoProceed: true,
        debug: true,
        logger: Uppy.debugLogger,
      });
      
      uppy.use(ActiveStorageUpload, {
        directUploadUrl: '/rails/active_storage/direct_uploads'
      })
      
      uppy.on('complete', res => {
        res.successful.forEach(file => {
          this.saveDocument(file, {
            document_category_id: this.activeCategoryId,
            opportunity_id: this.opportunity_id
          });
        });

        setTimeout(() => {
          uppy.reset();
          this.uppyOpen = false;
          this.activeCategoryId = '';
        }, 800);
      });

      return uppy;
    }
  },
  methods: {
    loadDocuments: function() {
      axios.get(`/staff/opportunities/${this.opportunity_id}/documents/grouped.json`)
           .then(res => {
             this.document_categories = res.data.document_categories;
           });
    },
    toggleUppy: function(categoryId) {
      this.uppyOpen = true;
      this.activeCategoryId = categoryId;
    },
    saveDocument: function(file, data) {
      axios.post(`/staff/opportunities/${data.opportunity_id}/documents.json`, {
        document: {
          ...data,
          file: file.response.signed_id
        },
        authenticity_token: this.csrfToken
      }).then(res => {
        let newDocs = [...this.document_categories];
        const categoryIndex = newDocs.findIndex(dc => dc.id === data.document_category_id);

        newDocs[categoryIndex].documents = [
          ...newDocs[categoryIndex].documents,
          res.data
        ];

        this.document_categories = newDocs;
      })
    },
    updateDocument: function(document, change) {
      axios.patch(`/staff/documents/${document.id}.json`, {
        document: change,
        authenticity_token: this.csrfToken
      }).then(res => {
        this.document_categories = res.data.document_categories;
      });
    },
    handleUppyClose: function() {
      this.uppyOpen = false;
    }
  }
}
</script>