<template>
  <div>
    <div class="flex space-x-4">
      <div class="flex-auto">
        <label class='font-semibold text-gray-800'>Client Stage Name</label>
        <input type="text" :value="name" @change="name = $event.target.value; saveStage()" class="bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block w-full appearance-none leading-normal" placeholder='Stage name'>
      </div>
      <div class="flex-1">
        <label class='font-semibold text-gray-800 block'>For Workflow Stage</label>
        <select @change="workflow_stage_id = $event.target.value; saveStage()" class="form-select w-full">
          <option value="">All Stages</option>
          <option v-for="stage in workflow_stages" :key="stage.id" :value="stage.id" :selected="stage.id == workflow_stage_id">{{ stage.name }}</option>
        </select>
      </div>
      <div class="flex-1">
        <label class='font-semibold text-gray-800 block'>Client Managed?</label>
        <select v-model="client_managed" @change="client_managed = $event.target.value; saveStage()" class="form-select w-full">
          <option :value="false">No</option>
          <option :value="true">Yes</option>
        </select>
      </div>
    </div>
    <div class="flex items-center justify-between mt-4">
      <h3 class="font-bold text-xl text-gray-800">Client Stage Tasks</h3>
      <a href="#" @click.prevent="addTask" class="inline-flex items-center justify-center px-3 py-1 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-brand-dark hover:bg-brand-light focus:outline-none focus:shadow-outline transition duration-150 ease-in-out">
        <svg class="w-6 h-6 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path></svg>
        Add New Client Stage Task
      </a>
    </div>
    <div>
      <draggable v-model="tasks" @start="drag=true" @end="drag=false; saveStage()">
        <div v-for="task in tasks" :key="`task-${task.id}`">
          <!-- When editing -->
          <div v-if="task.editing" class="bg-gray-200 rounded px-5 py-3 mt-4">
            <div class="flex-col space-y-2">
              <div class="flex items-center">
                <div class="flex-1 mr-4">
                  <label class='font-semibold text-gray-800'>Task Name</label>
                  <input type="text" v-model="task.name" class="bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block w-full appearance-none leading-normal" placeholder='Task name'>
                </div>
                <div>
                  <label class='font-semibold text-gray-800'>Client Managed?</label>
                  <select v-model="task.client_managed" class="form-select w-full">
                    <option :value="false">No</option>
                    <option :value="true">Yes</option>
                  </select>
                </div>
              </div>
              <div class="flex-auto mt-4">
                <label class='font-semibold text-gray-800'>Task Description</label>
                <textarea v-model="task.description" cols="30" rows="4" class="bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block w-full appearance-none leading-normal"></textarea>
              </div>

              <div class="flex-1">
                <label class='font-semibold text-gray-800 block'>Client Document Category</label>
                <select @change="task.client_document_category_id = $event.target.value; task.client_document_sub_category_id = ''" class="form-select w-full">
                  <option value="">Nominate A Document Category</option>
                  <option v-for="cat in document_categories" :key="cat.id" :value="cat.id" :selected="cat.id == task.client_document_category_id">{{ cat.name }}</option>
                </select>
              </div>

              <div class="flex-1">
                <label class='font-semibold text-gray-800 block'>Client Document Sub Category</label>
                <select @change="task.client_document_sub_category_id = $event.target.value; task.client_document_category_id = ''" class="form-select w-full">
                  <option value="">Or Nominate A Document Sub-Category</option>
                  <option v-for="cat in document_sub_categories" :key="cat.id" :value="cat.id" :selected="cat.id == task.client_document_sub_category_id">{{ cat.name }}</option>
                </select>
              </div>

              <div class="flex-1">
                <label class='font-semibold text-gray-800 block'>Client Learning Centre</label>
                <select @change="task.learning_centre_id = $event.target.value; fetchLearningData('learning_centres', task.learning_centre_id )" class="form-select w-full">
                  <option value="">Select Learning Centre</option>
                  <option v-for="lc in learning_centres" :key="lc.id" :value="lc.id" :selected="lc.id == task.learning_centre_id ">{{ lc.name }}</option>
                </select>
              </div>

              <div class="flex-1">
                <label class='font-semibold text-gray-800 block'>Client Learning Category</label>
                <select @change="task.learning_category_id = $event.target.value; fetchLearningData('learning_categories', task.learning_category_id )" class="form-select w-full">
                  <option value="">Select Learning Category</option>
                  <option v-for="cat in learning_categories" :key="cat.id" :value="cat.id" :selected="cat.id == task.learning_category_id ">{{ cat.name }}</option>
                </select>
              </div>

              <div class="flex-1">
                <label class='font-semibold text-gray-800 block'>Client Learning Step</label>
                <select @change="task.learning_step_id = $event.target.value; fetchLearningData('learning_steps', task.learning_step_id)" class="form-select w-full">
                  <option value="">Select Learning Step</option>
                  <option v-for="cat in learning_steps" :key="cat.id" :value="cat.id" :selected="cat.id == task.learning_step_id">{{ cat.name }}</option>
                </select>
              </div>

              <div class="flex-1">
                <label class='font-semibold text-gray-800 block'>Client Learning Topics</label>
                <select @change="task.learning_topic_id = $event.target.value; loadLearningSubTopic(task)" class="form-select w-full">
                  <option value="">Nominate Learning Topic</option>
                  <option v-for="cat in learning_topics" :key="cat.id" :value="cat.id" :selected="cat.id == task.learning_topic_id">{{ cat.name }}</option>
                </select>
              </div>

              <div class="flex-1">
                <label class='font-semibold text-gray-800 block'>Client Learning Sub Topics</label>
                <select @change="task.learning_sub_topic_id = $event.target.value;" class="form-select w-full">
                  <option value="">Nominate Learning Sub Topic</option>
                  <option v-for="cat in learning_sub_topics" :key="cat.id" :value="cat.id" :selected="cat.id == task.learning_sub_topic_id">{{ cat.name }}</option>
                </select>
              </div>

            </div>
            <a href="#" @click.prevent="task.editing = false; saveStage()" class="inline-flex items-center justify-center mt-2 px-3 py-1 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-brand-dark hover:bg-brand-light focus:outline-none focus:shadow-outline transition duration-150 ease-in-out">
              Save Task
            </a>
          </div>
          <!-- Regular task view -->
          <div v-else-if="(showKept && !task.discarded_at) || !showKept" class="bg-gray-100 rounded px-5 py-3 mt-3 flex items-center justify-between" :class="task.discarded_at ? 'bg-red-100' : ''">
            <div class="flex items-center">
              <svg class="w-4 h-4 mr-2 text-gray-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
              <h4 class="font-bold text-gray-800 mr-6">{{ task.name }}</h4>
              <a href="#" @click.prevent="addSubTask(task)" class="text-blue-500 inline-flex items-center">
                <svg class="w-4 h-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path></svg>
                Add Sub-Task
              </a>
            </div>
            <div class="flex items-center">
              <a href="#" @click.prevent="loadTaskData(task)" class="text-blue-500 mr-4">
                <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"></path></svg>
              </a>
              <a v-if="task.discarded_at" href="#" @click.prevent="undiscardTask(task.id)" class="text-blue-500">
                <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"></path></svg>
              </a>
              <a v-else href="#" @click.prevent="discardTask(task.id)" class="text-red-500" data-confirm="Are you sure you want to archive this task and all sub-tasks?">
                <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path></svg>
              </a>
            </div>
          </div>
          <draggable v-model="task.sub_tasks" @start="drag=true" @end="drag=false;saveStage()">
            <div v-for="subTask in task.sub_tasks" :key="`subtask-${subTask.id}`" class="ml-8 mt-2">
              <div v-if="subTask.editing" class="bg-gray-300 rounded px-5 py-3">
                <div class="flex-col space-y-2">
                  <div class="flex items-center">
                    <div class="flex-auto mr-4">
                      <label class='font-semibold text-gray-800'>Sub Task Name</label>
                      <input type="text" v-model="subTask.name" class="bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block w-full appearance-none leading-normal" placeholder='Task name'>
                    </div>
                    <div>
                      <label class='font-semibold text-gray-800'>Client Managed?</label>
                      <select v-model="subTask.client_managed" class="form-select w-full">
                        <option :value="false">No</option>
                        <option :value="true">Yes</option>
                      </select>
                    </div>
                  </div>
                  <div class="flex-auto mt-4">
                    <label class='font-semibold text-gray-800'>Task Description</label>
                    <textarea v-model="subTask.description" cols="30" rows="4" class="bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block w-full appearance-none leading-normal"></textarea>
                  </div>

                  <div class="flex-1">
                    <label class='font-semibold text-gray-800 block'>Client Document Category</label>
                    <select @change="subTask.client_document_category_id = $event.target.value; subTask.client_document_sub_category_id = ''" class="form-select w-full">
                      <option value="">Nominate A Document Category</option>
                      <option v-for="cat in document_categories" :key="cat.id" :value="cat.id" :selected="cat.id == subTask.client_document_category_id">{{ cat.name }}</option>
                    </select>
                  </div>

                  <div class="flex-1">
                    <label class='font-semibold text-gray-800 block'>Client Document Sub Category</label>
                    <select @change="subTask.client_document_sub_category_id = $event.target.value; subTask.client_document_category_id = ''" class="form-select w-full">
                      <option value="">Or Nominate A Document Sub-Category</option>
                      <option v-for="cat in document_sub_categories" :key="cat.id" :value="cat.id" :selected="cat.id == subTask.client_document_sub_category_id">{{ cat.name }}</option>
                    </select>
                  </div>


                  <div class="flex-1">
                    <label class='font-semibold text-gray-800 block'>Client Learning Centre</label>
                    <select @change="subTask.learning_centre_id = $event.target.value; fetchLearningData('learning_centres', subTask.learning_centre_id )" class="form-select w-full">
                      <option value="">Select Learning Centre</option>
                      <option v-for="lc in learning_centres" :key="lc.id" :value="lc.id" :selected="lc.id == subTask.learning_centre_id ">{{ lc.name }}</option>
                    </select>
                  </div>

                  <div class="flex-1">
                    <label class='font-semibold text-gray-800 block'>Client Learning Category</label>
                    <select @change="subTask.learning_category_id = $event.target.value; fetchLearningData('learning_categories', subTask.learning_category_id )" class="form-select w-full">
                      <option value="">Select Learning Category</option>
                      <option v-for="cat in learning_categories" :key="cat.id" :value="cat.id" :selected="cat.id == subTask.learning_category_id ">{{ cat.name }}</option>
                    </select>
                  </div>

                  <div class="flex-1">
                    <label class='font-semibold text-gray-800 block'>Client Learning Step</label>
                    <select @change="subTask.learning_step_id = $event.target.value; fetchLearningData('learning_steps', subTask.learning_step_id)" class="form-select w-full">
                      <option value="">Select Learning Step</option>
                      <option v-for="cat in learning_steps" :key="cat.id" :value="cat.id" :selected="cat.id == subTask.learning_step_id">{{ cat.name }}</option>
                    </select>
                  </div>

                  <div class="flex-1">
                    <label class='font-semibold text-gray-800 block'>Client Learning Topics</label>
                    <select @change="subTask.learning_topic_id = $event.target.value;loadLearningSubTopic(subTask)" class="form-select w-full">
                      <option value="">Nominate Learning Topic</option>
                      <option v-for="cat in learning_topics" :key="cat.id" :value="cat.id" :selected="cat.id == subTask.learning_topic_id">{{ cat.name }}</option>
                    </select>
                  </div>

                  <div class="flex-1">
                    <label class='font-semibold text-gray-800 block'>Client Learning Sub Topics</label>
                    <select @change="subTask.learning_sub_topic_id = $event.target.value;" class="form-select w-full">
                      <option value="">Nominate Learning Sub Topic</option>
                      <option v-for="cat in learning_sub_topics" :key="cat.id" :value="cat.id" :selected="cat.id == subTask.learning_sub_topic_id">{{ cat.name }}</option>
                    </select>
                  </div>
                </div>
                <a href="#" @click.prevent="subTask.editing = false; saveStage()" class="inline-flex items-center justify-center mt-2 px-3 py-1 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-brand-dark hover:bg-brand-light focus:outline-none focus:shadow-outline transition duration-150 ease-in-out">
                  Save Sub-Task
                </a>
              </div>
              <div v-else-if="(showKept && !subTask.discarded_at) || !showKept" class="bg-gray-200 rounded px-5 py-3 mt-3 flex items-center justify-between" :class="subTask.discarded_at ? 'bg-red-100' : ''">
                <div class="flex items-center">
                  <svg class="w-4 h-4 mr-2 text-gray-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg>
                  <h4 class="font-bold text-gray-800 mr-6">{{ subTask.name }}</h4>
                </div>
                <div class="flex items-center">
                  <a href="#" @click.prevent="loadTaskData(subTask)" class="text-blue-500 mr-4">
                    <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"></path></svg>
                  </a>
                  <a v-if="subTask.discarded_at" href="#" @click.prevent="undiscardSubTask(task.id, subTask.id)" class="text-blue-500">
                    <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"></path></svg>
                  </a>
                  <a v-else href="#" @click.prevent="discardSubTask(task.id, subTask.id)" class="text-red-500" data-confirm="Are you sure you want to archive this sub-task?">
                    <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path></svg>
                  </a>
                </div>
              </div>
            </div>
          </draggable>
        </div>
      </draggable>
      <div class="flex items-center justify-center mt-8 text-blue-500">
        <a href="#" @click.prevent="showKept = !showKept">Toggle Archived Tasks and Sub-Tasks</a>
      </div>
    </div>
  </div>
</template>
<script>
  import draggable from 'vuedraggable';
  import axios from 'axios';

  export default {
    components: { draggable },
    data: function() {
      return {
        id: '',
        name: '',
        workflow_stage_id: '',
        client_managed: false,
        tasks: [],
        workflow_stages: [],
        document_sub_categories: [],
        document_categories: [],
        learning_categories: [],
        learning_centres: [],
        learning_steps: [],
        learning_topics: [],
        learning_sub_topics: [],
        position: '',
        editing: true,
        showKept: true,
      }
    },
    mounted() {
      this.id = document.getElementById('client-stage-id').dataset.id;
      this.loadStage();
    },
    methods: {
      addTask() {
        this.tasks = [
          ...this.tasks,
          {
            id: null,
            name: '',
            description : '',
            client_document_category_id : '',
            client_document_sub_category_id : '',
            editing: true,
            client_managed: false,
            sub_tasks: []
          }
        ]
      },
      discardTask(id) {
        const tasks = [...this.tasks];
        tasks.find(t => t.id === id).discarded_at = new Date();
        tasks.find(t => t.id === id).sub_tasks = tasks.find(t => t.id === id).sub_tasks.map(st => {
          return {
            ...st,
            discarded_at: new Date()
          }
        });
        this.tasks = tasks;
        this.saveStage();
      },
      undiscardTask(id) {
        const tasks = [...this.tasks];
        tasks.find(t => t.id === id).discarded_at = null;
        tasks.find(t => t.id === id).sub_tasks = tasks.find(t => t.id === id).sub_tasks.map(st => {
          return {
            ...st,
            discarded_at: null
          }
        });
        this.tasks = tasks;
        this.saveStage();
      },
      addSubTask(task) {
        task.sub_tasks = [
          ...task.sub_tasks,
          {
            id: null,
            name: '',
            description: '',
            client_document_category_id : '',
            client_document_sub_category_id : '',
            editing: true,
            client_managed: false
          }
        ]
      },
      discardSubTask(taskId, id) {
        const tasks = [...this.tasks];

        tasks.find(t => t.id === taskId).sub_tasks = tasks.find(t => t.id === taskId).sub_tasks.map(st => {
          if (id === st.id) {
            return {
              ...st,
              discarded_at: new Date()
            }
          } else {
            return st;
          }
        });
        this.tasks = tasks;
        this.saveStage();
      },
      undiscardSubTask(taskId, id) {
        const tasks = [...this.tasks];

        tasks.find(t => t.id === taskId).sub_tasks = tasks.find(t => t.id === taskId).sub_tasks.map(st => {
          if (id === st.id) {
            return {
              ...st,
              discarded_at: null
            }
          } else {
            return st;
          }
        });
        this.tasks = tasks;
        this.saveStage();
      },
      loadStage() {
        const url = `/staff/client_stages/${this.id}.json`;

        axios.get(url)
             .then(res => {
               this.name = res.data.name;
               this.workflow_stage_id = res.data.workflow_stage_id;
               this.client_managed = res.data.client_managed;
               this.client_document_category_id = res.data.client_document_category_id;
               this.client_document_sub_category_id = res.data.client_document_sub_category_id;
               this.tasks = res.data.tasks;
               this.workflow_stages = res.data.workflow_stages;
               this.document_sub_categories = res.data.document_sub_categories;
               this.document_categories = res.data.document_categories;
               this.learning_centres = res.data.learning_centres;
             });
      },
      saveStage() {
        const url = `/staff/client_stages/${this.id}.json`;
        const csrf = document.getElementsByName('csrf-token')[0].content;
        const headers = { 'x-csrf-token': csrf };

        const data = {
          name: this.name,
          workflow_stage_id: this.workflow_stage_id,
          client_managed: this.client_managed,
          client_tasks_attributes: this.tasks.map((t, index) => {
            return {
              id: t.id === null ? '' : t.id,
              name: t.name,
              description: t.description,
              position: index,
              discarded_at: t.discarded_at,
              client_document_category_id: t.client_document_category_id,
              learning_topic_id: t.learning_topic_id,
              learning_category_id: t.learning_category_id,
              learning_step_id: t.learning_step_id,
              learning_sub_topic_id: t.learning_sub_topic_id,
              learning_centre_id: t.learning_centre_id,
              client_managed: this.client_managed || t.client_managed,
              client_document_sub_category_id: t.client_document_sub_category_id,
              client_sub_tasks_attributes: t.sub_tasks.map((st, i) => {
                return {
                  id: st && st.id === null ? '' : st.id,
                  name: st.name,
                  description: st.description,
                  position: i,
                  discarded_at: st.discarded_at,
                  client_document_category_id: st.client_document_category_id,
                  client_document_sub_category_id: st.client_document_sub_category_id,
                  learning_topic_id: st.learning_topic_id,
                  learning_category_id: st.learning_category_id,
                  learning_step_id: st.learning_step_id,
                  learning_sub_topic_id: st.learning_sub_topic_id,
                  learning_centre_id: st.learning_centre_id,
                  client_managed: this.client_managed || t.client_managed || st.client_managed
                }
              })
            }
          })
        };
        axios.patch(url, data, { headers }).then(res => {
          this.tasks = res.data.tasks;
        });
      },
      fetchLearningData(type, id) {
        if (type === 'learning_steps'){
          this.learning_topics = this.learning_steps.find(step => step.id == id).learning_topics;
          this.learning_sub_topics = [];
        } else if (type === 'learning_centres') {
          this.learning_categories = this.learning_centres.find(lc => lc.id == id).learning_categories
          this.learning_steps = [];
          this.learning_topics = [];
          this.learning_sub_topics = [];
        } else {
          this.learning_steps = this.learning_categories.find(cat => cat.id == id).learning_steps
          this.learning_topics = [];
          this.learning_sub_topics = [];
        }
      },
      loadLearningSubTopic(task) {
        let topic = this.learning_topics.find(t => t.id == task.learning_topic_id);
        if(topic && topic.sub_topics.length > 0) {
          this.learning_sub_topics = topic.sub_topics;
        } else {
          task.learning_sub_topic_id = null;
        }
      },
      loadTaskData(task) {
        if (task.learning_centre_id && task.learning_category_id && task.learning_step_id) {
          this.learning_categories = this.learning_centres.find(lc => task.learning_centre_id == lc.id) ?
              this.learning_centres.find(lc => task.learning_centre_id == lc.id).learning_categories : [];
          this.learning_steps = this.learning_categories.length ?
              this.learning_categories.find(cat => task.learning_category_id == cat.id).learning_steps : []
          this.learning_topics = this.learning_steps.length ?
              this.learning_steps.find(step => step.id == task.learning_step_id).learning_topics : []
          this.learning_sub_topics = this.learning_topics.length && this.learning_topics.find(topic => topic.id == task.learning_topic_id) ?
              this.learning_topics.find(topic => topic.id == task.learning_topic_id).sub_topics : []
        }
        task.editing = true
      }
    }
  }
</script>
