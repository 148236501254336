<template>
  <div>
    <div v-show="alert" class="rounded-md bg-green-50 p-4 mb-4">
      <div class="flex justify-center">
        <div class="flex-shrink-0">
          <svg class="h-5 w-5 text-green-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
          </svg>
        </div>
        <div class="ml-3">
          <div class="text-sm leading-5 text-green-700">
            <p>{{ alert }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="flex justify-between items-center mt-4 mb-2">
      <h4 class="font-bold text-lg text-gray-800">Project Learning Templates</h4>
      <a @click.prevent="addCentre" class="bg-brand-dark hover:bg-brand-light text-white font-bold py-2 px-4 rounded">Add Learning Template</a>
    </div>
    <div class="bg-gray-50 text-center p-4">
      <p>If the list below is empty, this project is using the default learning templates for the current workflow stage</p>
    </div>
    <div class="mb-4">
      <draggable v-model="learning_centres" @start="drag=true" @end="drag=false;" class="flex flex-col space-y-2">
        <div v-for="(centre, index) in learning_centres" :key="centre.id" class="p-3 bg-gray-50 rounded flex flex-col space-y-4 border border-gray-200 selection-option" :class="[centre._destroy ? 'bg-red-100' : '']">
          <div class="flex items-center space-x-4 space-between">
            <div class="flex-1">
              <label class='font-semibold'>Learning Template</label>
              <select v-model="centre.learning_centre_id" required="true" class="bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block w-full appearance-none leading-normal form-select">
                <option :value="null">Nominate the learning template</option>
                <option v-for="learningCentre in all_learning_centres" :key="learningCentre.id" :value="learningCentre.id">
                  {{ learningCentre.name }}
                </option>
              </select>
            </div>
            <a @click.prevent="toggleDelete(index)" class="text-red-500 cursor-pointer">
              <svg v-if="centre._destroy" class="h-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 10h10a8 8 0 018 8v2M3 10l6 6m-6-6l6-6"></path></svg>
              <svg v-else class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path></svg>
            </a>
          </div>
        </div>
      </draggable>
    </div>
    <div class="mt-4 flex justify-between">
      <a :href="`/staff/opportunities/${this.id}/client/learning_centres`" class="text-blue-500">
        Back to Client Management
      </a>
      <a @click.prevent="saveTemplates" class="bg-brand-dark hover:bg-brand-light text-white font-bold py-2 px-4 rounded cursor-pointer">
        Update Learning Templates
      </a>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue';
  import draggable from 'vuedraggable';
  import axios from 'axios';

  export default {
    components: { draggable },
    data: function() {
      return {
        alert: null,
        id: '',
        learning_centres: [],
        all_learning_centres: []
      }
    },
    mounted() {
      const idEl = document.getElementById('opportunity-id');
      this.id = idEl.dataset.id;

      this.loadTempaltes();
    },
    methods: {
      loadTempaltes() {
        const url = `/staff/opportunities/${this.id}/learning_centres.json`;
        
        axios.get(url).then(({ data }) => {
          this.learning_centres = data.opp_learning_centres;
          this.all_learning_centres = data.learning_centres;
        });
      },
      addCentre() {
        this.learning_centres = [
          ...this.learning_centres,
          {
            id: null,
            learning_centre_id: null,
            position: this.learning_centres.length - 1,
            _destroy: false
          }
        ];
      },
      toggleDelete(index) {
        Vue.set(this.learning_centres[index], '_destroy', !this.learning_centres[index]._destroy);
      },
      saveTemplates() {
        const url = `/staff/opportunities/${this.id}/learning_centre.json`;
        const csrf = document.getElementsByName('csrf-token')[0].content;
        const headers = { 'x-csrf-token': csrf };
        const data = {
          opportunity: {
            opportunity_learning_centres_attributes: this.learning_centres.filter(lc => lc.learning_centre_id).map((t, index) => {
              return {
                id: t.id,
                learning_centre_id: t.learning_centre_id,
                position: index,
                _destroy: t._destroy
              }
            })
          }
        };
        
        axios.patch(url, data, { headers }).then(({ data }) => {
          this.learning_centres = data.opp_learning_centres;
          this.alert = 'Opportunity learning templates updated successfully';
        });
      }
    }
  }
</script>