<template>
  <div class="ml-8 px-3 py-1 rounded" :class="discardedStyle">
    <div class="grid grid-cols-12 space-x-1 items-center">
      <p v-if="document.current" class="font-bold col-span-2">Displayed To Client</p>
      <p v-else-if="document.discarded_at" class="font-bold col-span-2">Archived</p>
      <p v-else class="font-medium col-span-2">Superseded V{{ document.version }}</p>
      <h4 class="col-span-4 font-light text-sm">{{ document.name }}</h4>
      <div class="col-span-6 flex items-center justify-end space-x-2">
        <div class="bg-brand-dark rounded-full h-8 w-8 p-1 text-white text-sm flex items-center justify-center" v-if="document.account_user && document.account_user.length > 0">{{ document.account_user }}</div>
        <p class="text-sm">{{ document.uploaded }}</p>
        <a v-if="document.current" href="#" @click.prevent="archive" class="ml-4 px-4 py-1 rounded-lg bg-brand-dark text-white font-semibold">
          Archive
        </a>
        <div v-else-if="document.discarded_at" class="flex-items-center space-x-2 justify-end">
          <a href="#" @click.prevent="unarchive" class="ml-4 px-4 py-1 rounded-lg bg-brand-dark text-white font-semibold">
            Unarchive
          </a>
          <a href="#" @click.prevent="deleteDoc" class="ml-4 px-4 py-1 rounded-lg bg-brand-dark text-red font-semibold">
            Delete
          </a>
        </div>
        <a v-else href="#" @click.prevent="makeActive" class="ml-4 px-4 py-1 rounded-lg bg-brand-dark text-white font-semibold">
          Make Active
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';

export default {
  props: ['document', 'documentIndex', 'taskIndex', 'subTaskIndex', 'stageId', 'updateDocuments'],
  computed: {
    discardedStyle() {
      if (this.document.discarded_at) {
        return 'bg-gray-500 text-gray-100';
      } else {
        return 'bg-brand-light';
      }
    }
  },
  methods: {
    makeActive() {
      const url = `/staff/opp_client_documents/${this.document.id}.json`;
      const csrf = document.getElementsByName('csrf-token')[0].content;
      const headers = { 'x-csrf-token': csrf };
      const method = 'patch';
      const data = { client_document: { current: true } };

      axios({ url, method, data, headers }).then(res => {
        this.updateDocuments(this.stageId, this.taskIndex, this.subTaskIndex, res.data);
      });
    },
    archive() {
      const url = `/staff/opp_client_documents/${this.document.id}.json`;
      const csrf = document.getElementsByName('csrf-token')[0].content;
      const headers = { 'x-csrf-token': csrf };
      const method = 'patch';
      const data = { client_document: { discarded_at: new Date() } };

      axios({ url, method, data, headers }).then(res => {
        this.updateDocuments(this.stageId, this.taskIndex, this.subTaskIndex, res.data);
      });
    },
    unarchive() {
      const url = `/staff/opp_client_documents/${this.document.id}.json`;
      const csrf = document.getElementsByName('csrf-token')[0].content;
      const headers = { 'x-csrf-token': csrf };
      const method = 'patch';
      const data = { client_document: { discarded_at: null } };

      axios({ url, method, data, headers }).then(res => {
        this.updateDocuments(this.stageId, this.taskIndex, this.subTaskIndex, res.data);
      });
    },
    deleteDoc() {
      const url = `/staff/opp_client_documents/${this.document.id}.json`;
      const csrf = document.getElementsByName('csrf-token')[0].content;
      const headers = { 'x-csrf-token': csrf };
      const method = 'delete';

      axios({ url, method, headers }).then(res => {
        this.updateDocuments(this.stageId, this.taskIndex, this.subTaskIndex, res.data);
      });
    }
  }
}
</script>