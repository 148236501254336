import { Controller } from "@hotwired/stimulus";
import Sortable from "sortablejs";
import {FetchRequest, patch} from "@rails/request.js";

export default class extends Controller {
    static values = {
        responseKind: {
            type: String,
            default: 'html'
        },
        animation: {
            type: Number,
            default: 150
        },
        handle: String,
        group: {
            type: String,
            default: 'group'
        }
    }

    connect() {
        this.sortable = Sortable.create(this.element, {
            onEnd: this.onEnd.bind(this),
            animation: 150,
            handle: '.sortable-handle',
            group: {
                name: this.groupValue,
                put: this.groupValue
            },
        })
    }

    async onEnd ({ item, newIndex }) {
        if (!item.dataset.sortableUpdateUrl) return; //return if updateUrl hasn't been set
        let dropContainer = item.closest('.drop-container'); //set the container element we are dropping into

        const data = new FormData();
        data.append('position', newIndex + 1); // new position oof item dropped
        if(dropContainer) { data.append('container_id', dropContainer.dataset.id); } //add the id of the container we dropped into, as a param


        return await patch(item.dataset.sortableUpdateUrl, { body: data, responseKind: this.responseKindValue });
    }
}