<template>
  <table class="table-auto w-full table-collapse bg-white rounded-md shadow divide-y divide-gray-200">
    <thead>
      <tr class="border-b-gray-200">
        <th class="p-3 text-left sticky top-0 font-semibold text-gray-500 bg-gray-200 p-0 rounded-tl-md">Name</th>
        <th class="p-3 sticky top-0 font-semibold text-gray-500 bg-gray-200 p-0 rounded-tr-md"></th>
      </tr>
    </thead>
    <tbody class="divide-y divide-gray-200">
      <draggable v-model="funnel_stages" @start="drag=true" @end="drag=false;saveStages()" class="flex flex-col">
        <tr v-for="stage in funnel_stages" :key="stage.id" class="flex flex-auto justify-between">
          <td class="p-3 whitespace-no-wrap">
            <a :href="`/staff/funnel_stages/${stage.id}/edit`" class="font-semibold text-gray-800">{{stage.code + ' ' + stage.name }}</a>
          </td>
          <td class="p-3 whitespace-no-wrap text-gray-500">
            <a :href="`/staff/funnel_stages/${stage.id}`" data-method="delete" data-confirm="Are you sure you want to archive this job stage?">Archive</a>
          </td>
        </tr>
      </draggable>
    </tbody>
  </table>
</template>
<script>
  import draggable from 'vuedraggable';
  import axios from 'axios';

  export default {
    components: { draggable },
    data: function() {
      return {
        funnel_stages: []
      }
    },
    mounted() {
      this.loadStages();
    },
    methods: {
      loadStages() {
        const url = '/staff/funnel_stages.json';
        
        axios.get(url).then(res => {
          this.funnel_stages = res.data.funnel_stages;
        });
      },
      saveStages() {
        const url = `/staff/funnel_stages/reorder.json`;
        const csrf = document.getElementsByName('csrf-token')[0].content;
        const headers = { 'x-csrf-token': csrf };
        const data = {
          funnel_stages: this.funnel_stages.map((t, index) => {
            return {
              id: t.id,
              position: index,
            }
          })
        };
        
        axios.patch(url, data, { headers }).then(res => {
          this.tasks = res.data.tasks;
        });

        this.loadStages();
      }
    }
  }
</script>