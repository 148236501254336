<template>
  <div class="learning-step-editor">
    <div class="flex space-x-4">
      <div class="flex-auto">
        <label class='font-semibold text-gray-800'>Learning Step Name*</label>
        <input type="text" :value="name" @change="name = $event.target.value; saveStep()" class="bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block w-full appearance-none leading-normal" placeholder='Learning category name'>
        <p v-if="Object.keys(this.errors).length > 0" class="p-2 bg-red-500 text-white rounded text-center" >{{this.errors['name'].join(', ')}}</p>
      </div>
    </div>
    <div class="flex items-center justify-between mt-4">
      <h3 class="font-bold text-xl text-gray-800">Learning Topics</h3>
      <a href="#" @click.prevent="addLearningTopic" class="inline-flex items-center justify-center px-3 py-1 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-brand-dark hover:bg-brand-light focus:outline-none focus:shadow-outline transition duration-150 ease-in-out">
        <svg class="w-6 h-6 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path></svg>
        Add New Learning Topic
      </a>
    </div>
    <div>
      <draggable v-model="learning_topics" @start="drag=true" @end="drag=false; saveStep()" class="flex flex-col">
        <div v-for="(topic, topicIndex) in learning_topics" :key="`learning-topic-${topic.id}`">
          <!-- When editing -->
          <div v-if="topic.editing" class="bg-gray-200 rounded px-5 py-3 mt-4">
            <div class="flex items-center">
              <div class="flex-1 flex flex-col space-y-2">
                <div class="flex-1">
                  <label class='font-semibold text-gray-800'>Learning Topic Name<sup>*</sup></label>
                  <input type="text" v-model="topic.name" class="bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block w-full appearance-none leading-normal" placeholder='Learning topic name'>
                </div>
                <div class="flex-1">
                  <label class='font-semibold text-gray-800'>Training Content<sup>*</sup></label>
                  <VueTrix
                    v-model="topic.content"
                    placeholder="Enter topic training content"
                    @trix-attachment-add="file => handleTopicFile(file, topicIndex)"
                    :config="trixConfig"
                  />
                </div>
              </div>
            </div>
            <a href="#" @click.prevent="topic.editing = false; saveTopic(topic.id)" class="inline-flex items-center justify-center mt-2 px-3 py-1 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-brand-dark hover:bg-brand-light focus:outline-none focus:shadow-outline transition duration-150 ease-in-out">
              Save Topic
            </a>
          </div>
          <div v-else-if="(showKept && !topic.discarded_at) || !showKept" class="bg-gray-100 rounded px-5 py-3 mt-3 flex items-center justify-between" :class="topic.discarded_at ? 'bg-red-100' : ''">
            <div class="flex items-center">
              <svg class="w-4 h-4 mr-2 text-gray-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
              <h4 class="font-bold text-gray-800 mr-6">{{ topic.name }}</h4>
              <a href="#" @click.prevent="addSubTopic(topicIndex)" class="text-blue-500 inline-flex items-center">
                <svg class="w-4 h-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path></svg>
                Add Sub-Topic
              </a>
            </div>
            <div class="flex items-center">
              <a href="#" @click.prevent="topic.editing = true" class="text-blue-500 mr-4">
                <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"></path></svg>
              </a>
              <a v-if="topic.discarded_at" href="#" @click.prevent="undiscardLearningTopic(topic.id)" class="text-blue-500">
                <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"></path></svg>
              </a>
              <a v-else href="#" @click.prevent="discardLearningTopic(topic.id)" class="text-red-500">
                <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path></svg>
              </a>
            </div>
          </div>
          <draggable v-model="topic.sub_topics" @start="drag=true" @end="drag=false;saveStep()">
            <div v-for="subTopic in topic.sub_topics" :key="`sub-topic-${subTopic.id}`" class="ml-8 mt-2">
              <div v-if="subTopic.editing" class="bg-gray-300 rounded px-5 py-3">
                <div class="flex-col">
                  <div class="flex items-center">
                    <div class="flex flex-col flex-1 gap-y-2 mr-4">
                      <div>
                        <label class='font-semibold text-gray-800'>Sub-Topic Name</label>
                        <input type="text" v-model="subTopic.name" class="bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block w-full appearance-none leading-normal" placeholder='Sub-topic name'>
                      </div>
                      <div class="flex-1">
                        <label class='font-semibold text-gray-800'>Training Content<sup>*</sup></label>
                        <VueTrix
                          v-model="subTopic.content"
                          placeholder="Enter sub-topic training content"
                          @trix-attachment-add="file => handleTopicFile(file, topicIndex)"
                          :config="trixConfig"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <a href="#" @click.prevent="subTopic.editing = false; saveSubtopic(topic.id, subTopic.id)" class="inline-flex items-center justify-center mt-2 px-3 py-1 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-brand-dark hover:bg-brand-light focus:outline-none focus:shadow-outline transition duration-150 ease-in-out">
                  Save Sub-Topic
                </a>
              </div>
              <div v-else-if="(showKept && !subTopic.discarded_at) || !showKept" class="bg-gray-200 rounded px-5 py-3 mt-3 flex items-center justify-between" :class="subTopic.discarded_at ? 'bg-red-100' : ''">
                <div class="flex items-center">
                  <svg class="w-4 h-4 mr-2 text-gray-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg>
                  <h4 class="font-bold text-gray-800 mr-6">{{ subTopic.name }}</h4>
                </div>
                <div class="flex items-center">
                  <a href="#" @click.prevent="subTopic.editing = true" class="text-blue-500 mr-4">
                    <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"></path></svg>
                  </a>
                  <a v-if="subTopic.discarded_at" href="#" @click.prevent="undiscardSubTopic(topic.id, subTopic.id)" class="text-blue-500">
                    <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"></path></svg>
                  </a>
                  <a v-else href="#" @click.prevent="discardSubTopic(topic.id, subTopic.id)" class="text-red-500">
                    <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path></svg>
                  </a>
                </div>
              </div>
            </div>
          </draggable>
        </div>
      </draggable>
      <div class="flex items-center justify-center mt-8 text-blue-500">
        <a href="#" @click.prevent="showKept = !showKept">Toggle Archived Learning Topics</a>
      </div>
    </div>
  </div>
</template>
<script>
  import draggable from 'vuedraggable';
  import axios from 'axios';
  import Vue from 'vue';
  import VueTrix from "vue-trix";
  import { FileChecksum } from "@rails/activestorage/src/file_checksum";
  import { DirectUpload } from "@rails/activestorage"

  export default {
    components: { draggable, VueTrix },
    data: function() {
      return {
        id: '',
        name: '',
        discarded_at: null,
        learning_topics: [],
        editing: true,
        showKept: true,
        errors: {},
        trixConfig: {
          attachments: {
            preview: {
              caption: { name: false, size: false}
            }
          }
        }
      }
    },
    mounted() {
      this.id = document.getElementById('learning-step-id') ?
          document.getElementById('learning-step-id').dataset.id : null;
      this.loadTemplate();
    },
    methods: {
      addLearningTopic(e) {
        e.preventDefault();

        this.learning_topics = [
          ...this.learning_topics,
          {
            id: null,
            name: '',
            content: '',
            editing: true,
            sub_topics: []
          }
        ]
      },
      addSubTopic(index) {
        const subTopics = [
          ...this.learning_topics[index].sub_topics,
          {
            id: '',
            name: '',
            content: '',
            discarded_at: '',
            editing: true
          }
        ];

        this.learning_topics[index].sub_topics = subTopics;
      },
      handleTopicFile(event, topicIndex) {
        event.preventDefault();

        const url = '/rails/active_storage/direct_uploads';
        const csrfToken = document.getElementsByName('csrf-token')[0].content;

        const upload = new DirectUpload(event.attachment.file, url);

        upload.create((err, blob) => {
          if (err) {
            console.log(err);
          } else {
            console.log(blob);
            let attributes = {
              url: `/rails/active_storage/blobs/redirect/${blob.signed_id}/${blob.filename}`,
              href: `/rails/active_storage/blobs/redirect/${blob.signed_id}/${blob.filename}`
            };

            event.attachment.setAttributes(attributes);
          }
        });
      },
      discardLearningTopic(id) {
        if (confirm('Are you sure you want to archive this learning topic?')) {
          const topicIndex = this.learning_topics.findIndex(lt => lt.id == id);

          Vue.set(this.learning_topics[topicIndex], 'discarded_at', new Date());
          this.saveStep();
        }
      },
      undiscardLearningTopic(id) {
        const topicIndex = this.learning_topics.findIndex(t => t.id === id);

        Vue.set(this.learning_topics[topicIndex], 'discarded_at', null);
        this.saveStep();
      },
      discardSubTopic(topicId, id) {
        if (confirm('Are you sure you want to archive this learning sub-topic?')) {
          const topicIndex = this.learning_topics.findIndex(lt => lt.id == topicId);
          const subTopicIndex = this.learning_topics[topicIndex].sub_topics.findIndex(st => st.id == id);

          Vue.set(this.learning_topics[topicIndex].sub_topics[subTopicIndex], 'discarded_at', new Date());
          this.saveStep();
        }
      },
      undiscardSubTopic(topicId, id) {
        const topicIndex = this.learning_topics.findIndex(lt => lt.id == topicId);
        const subTopicIndex = this.learning_topics[topicIndex].sub_topics.findIndex(st => st.id == id);

        Vue.set(this.learning_topics[topicIndex].sub_topics[subTopicIndex], 'discarded_at', null);
        this.saveStep();
      },
      loadTemplate() {
        let url = `/staff/learning_steps/${this.id}.json`;

        axios.get(url)
             .then(res => {
               this.name = res.data.name;
               this.discarded_at = res.data.discarded_at;
               this.category_name = res.data.category_name;
               this.learning_topics = res.data.learning_topics;
             });
      },
      saveStep() {
        const url = `/staff/learning_steps/${this.id}.json`;
        const method = 'PATCH';
        const csrf = document.getElementsByName('csrf-token')[0].content;
        const headers = { 'x-csrf-token': csrf };
        const data = {
          name: this.name,
          learning_topics_attributes: this.learning_topics.map((lt, index) => {
            return {
              id: lt.id === null ? '' : lt.id,
              name: lt.name,
              discarded_at: lt.discarded_at,
              position: index,
              learning_sub_topics_attributes: lt.sub_topics.map((st, stIndex) => {
                return {
                  id: st.id === null ? '' : st.id,
                  name: st.name,
                  discarded_at: st.discarded_at,
                  position: stIndex
                }
              })
            }
          })
        };

        axios({ url, method, data, headers }).then(res => {
          this.id = res.data.id;
          this.name = res.data.name;
          this.errors = {};
          this.learning_topics = res.data.learning_topics;
        }).catch(error => {
          this.errors = error.response.data;
        });
      },
      saveTopic(topicId) {
        const url = `/staff/learning_steps/${this.id}.json`;
        const method = 'PATCH';
        const csrf = document.getElementsByName('csrf-token')[0].content;
        const headers = { 'x-csrf-token': csrf };
        let learningTopics = this.learning_topics.filter(lt => lt.id === topicId)
        const data = {
          name: this.name,
          learning_topics_attributes: learningTopics.map((lt) => {
            return {
              id: lt.id === null ? '' : lt.id,
              name: lt.name,
              content: lt.content,
            }
          })
        };

        axios({ url, method, data, headers }).then(res => {
          this.id = res.data.id;
          this.name = res.data.name;
          this.errors = {};
          this.learning_topics = res.data.learning_topics;
        }).catch(error => {
          this.errors = error.response.data;
        });
      },
      saveSubtopic(topicId, subTopicId) {
        const url = `/staff/learning_steps/${this.id}.json`;
        const method = 'PATCH';
        const csrf = document.getElementsByName('csrf-token')[0].content;
        const headers = { 'x-csrf-token': csrf };
        let learningTopic = this.learning_topics.find(lt => lt.id === topicId);
        let learningSubTopics = learningTopic.sub_topics.filter(st => st.id === subTopicId);
        const data = {
          name: this.name,
          learning_topics_attributes: {
            id: learningTopic.id,
            learning_sub_topics_attributes: learningSubTopics.map(st => {
              return {
                id: st.id === null ? '' : st.id,
                name: st.name,
                discarded_at: st.discarded_at,
                content: st.content
              }
            }),
          }
        };

        axios({ url, method, data, headers }).then(res => {
          this.id = res.data.id;
          this.name = res.data.name;
          this.errors = {};
          this.learning_topics = res.data.learning_topics;
        }).catch(error => {
          this.errors = error.response.data;
        });
      }
    }
  }
</script>
