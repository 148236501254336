import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    connect() {
        this.alertElements = document.getElementsByTagName("h5");
        this.replaceAlerts();
    }

    replaceAlerts() {
        this.alertElements.forEach (el => {
            let text = el.innerText;
            if(text.substring(0,9) == 'Important') { text = text.substring(9) }
            el.innerHTML = this.alertImportant(text);
        });
    }

    alertImportant(text) {
        return `
        <div class="">
          <div class="flex items-center rounded-md bg-blue-100">
            <div class="text-blue-500 p-2">
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-bulb" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M3 12h1m8 -9v1m8 8h1m-15.4 -6.4l.7 .7m12.1 -.7l-.7 .7" /><path d="M9 16a5 5 0 1 1 6 0a3.5 3.5 0 0 0 -1 3a2 2 0 0 1 -4 0a3.5 3.5 0 0 0 -1 -3" />
                    <path d="M9.7 17l4.6 0" />
                </svg>
            </div>
            <div class="border-l-2 border-white p-2">
              <h3 class="text-sm font-medium text-blue-500">Important</h3>
              <div class="mt-1 text-sm text-gray-800">
                <p>${text}</p>
              </div>
            </div>
          </div>
        </div>`;
    }
}