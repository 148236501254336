<template>
  <div>
    <div class="flex space-x-4">
      <div class="flex-auto">
        <label class='font-semibold text-gray-800'>Category Name</label>
        <input type="text" :value="name" @change="name = $event.target.value; saveCategory()" class="bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block w-full appearance-none leading-normal" placeholder='Document category name'>
      </div>
      <div class="flex flex-col">
        <label class='font-semibold text-gray-800'>
          Requires Signing
        </label>
        <select :value="requires_signing" @change="requires_signing = $event.target.value; saveCategory()" class="form-select">
          <option :value="false">No</option>
          <option :value="true">Yes</option>
        </select>
      </div>
      <div class="flex flex-col">
        <label class='font-semibold text-gray-800'>
          Allowed Documents
        </label>
        <select :value="allow_multiple_documents" @change="allow_multiple_documents = $event.target.value; saveCategory()" class="form-select">
          <option :value="false">Single</option>
          <option :value="true">Multiple</option>
        </select>
      </div>
    </div>
    <div class="flex pt-2">
      <label class='font-semibold text-gray-800'>
        Disable Visibility For:
      </label>
      <div v-for="role in roles">
        <div class="flex flex-col pl-5">
          <label class='font-semibold text-gray-800'>
            <input @change="saveCategory()" v-model="restricted_roles" :value="role.value" type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded">
            {{ role.key }}
          </label>
        </div>
      </div>
    </div>
    <div class="flex items-center justify-between mt-4">
      <h3 class="font-bold text-xl text-gray-800">Sub-Categories</h3>
      <a href="#" @click.prevent="addSubCategory" class="inline-flex items-center justify-center px-3 py-1 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-brand-dark hover:bg-brand-light focus:outline-none focus:shadow-outline transition duration-150 ease-in-out">
        <svg class="w-6 h-6 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path></svg>
        Add New Document Sub-Category
      </a>
    </div>
    <div>
      <draggable v-model="subCategories" @start="drag=true" @end="drag=false; saveCategory()">
        <div v-for="subCategory in subCategories" :key="`sub-category-${subCategory.id}`">
          <!-- When editing -->
          <div v-if="subCategory.editing" class="bg-gray-200 rounded px-5 py-3 mt-4">
            <div class="flex items-center">
              <div class="flex-1 flex space-x-2">
                <div class="flex-1">
                  <label class='font-semibold text-gray-800'>Sub-Category Name</label>
                  <input type="text" v-model="subCategory.name" class="bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block w-full appearance-none leading-normal" placeholder='Sub-category name'>
                </div>
                <div class="flex flex-1 flex-col">
                  <label class='font-semibold text-gray-800'>
                    Requires Signing
                  </label>
                  <select v-model="subCategory.requires_signing" class="form-select">
                    <option :value="false">No</option>
                    <option :value="true">Yes</option>
                  </select>
                </div>
                <div class="flex flex-1 flex-col">
                  <label class='font-semibold text-gray-800'>
                    Allowed Documents
                  </label>
                  <select v-model="subCategory.allow_multiple_documents" class="form-select">
                    <option :value="false">Single</option>
                    <option :value="true">Multiple</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="flex pt-2">
              <label class='font-semibold text-gray-800'>
                Disable Visibility For:
              </label>
              <div v-for="role in roles">
                <div class="flex flex-col pl-5">
                  <label class='font-semibold text-gray-800'>
                    <input v-model="subCategory.restricted_roles" :value="role.value" type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded">
                    {{ role.key }}
                  </label>
                </div>
              </div>
            </div>
            <a href="#" @click.prevent="subCategory.editing = false; saveCategory()" class="inline-flex items-center justify-center mt-2 px-3 py-1 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-brand-dark hover:bg-brand-light focus:outline-none focus:shadow-outline transition duration-150 ease-in-out">
              Save Sub-Category
            </a>
          </div>
          <div v-else-if="(showKept && !subCategory.discarded_at) || !showKept" class="bg-gray-100 rounded px-5 py-3 mt-3 flex items-center justify-between" :class="subCategory.discarded_at ? 'bg-red-100' : ''">
            <div class="flex items-center">
              <svg class="w-4 h-4 mr-2 text-gray-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
              <h4 class="font-bold text-gray-800 mr-6">{{ subCategory.name }}</h4>
            </div>
            <div class="flex items-center">
              <a href="#" @click.prevent="subCategory.editing = true" class="text-blue-500 mr-4">
                <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"></path></svg>
              </a>
              <a v-if="subCategory.discarded_at" href="#" @click.prevent="undiscardSubCategory(subCategory.id)" class="text-blue-500">
                <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"></path></svg>
              </a>
              <a v-else href="#" @click.prevent="discardSubCategory(subCategory.id)" class="text-red-500">
                <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path></svg>
              </a>
            </div>
          </div>
        </div>
      </draggable>
      <div class="flex items-center justify-center mt-8 text-blue-500">
        <a href="#" @click.prevent="showKept = !showKept">Toggle Archived Sub-Categories</a>
      </div>
    </div>
  </div>
</template>
<script>
  import draggable from 'vuedraggable';
  import axios from 'axios';
  import Vue from 'vue';

  export default {
    components: { draggable },
    data: function() {
      return {
        id: '',
        name: '',
        discarded_at: null,
        requires_signing: false,
        allow_multiple_documents: false,
        subCategories: [],
        roles: [],
        editing: true,
        showKept: true,
        restricted_roles: []
      }
    },
    mounted() {
      this.id = document.getElementById('document-category-id').dataset.id;
      this.loadCategory();
    },
    methods: {
      addSubCategory() {
        this.subCategories = [
          ...this.subCategories,
          {
            id: null,
            name: '',
            requires_signing: false,
            allow_multiple_documents: false,
            editing: true,
            restricted_roles: []
          }
        ]
      },
      discardSubCategory(id) {
        if (confirm('Are you sure you want to archive this sub-category?')) {
          const subCatIndex = this.subCategories.findIndex(sc => sc.id === id);

          Vue.set(this.subCategories[subCatIndex], 'discarded_at', new Date());
          this.saveCategory();
        }
      },
      undiscardSubCategory(id) {
        const subCatIndex = this.subCategories.findIndex(t => t.id === id);

        Vue.set(this.subCategories[subCatIndex], 'discarded_at', null);
        this.saveCategory();
      },
      loadCategory() {
        const url = `/staff/client_document_categories/${this.id}.json`;

        axios.get(url)
             .then(res => {
               this.name = res.data.name;
               this.discarded_at = res.data.discarded_at;
               this.requires_signing = res.data.requires_signing;
               this.allow_multiple_documents =  res.data.allow_multiple_documents;
               this.subCategories = res.data.sub_categories;
               this.roles = res.data.roles;
               this.restricted_roles = res.data.restricted_roles;
             });
      },
      saveCategory() {
        let url = '/staff/client_document_categories';
        let method = 'POST';

        if (this.id) {
          url = `${url}/${this.id}.json`;
          method = 'PATCH';
        } else {
          url = `${url}.json`;
        }
        const csrf = document.getElementsByName('csrf-token')[0].content;
        const headers = { 'x-csrf-token': csrf };
        const data = {
          name: this.name,
          requires_signing: this.requires_signing,
          allow_multiple_documents: this.allow_multiple_documents,
          restricted_roles: this.restricted_roles,
          sub_categories_attributes: this.subCategories.map((sc, index) => {
            return {
              id: sc.id === null ? '' : sc.id,
              name: sc.name,
              position: index,
              discarded_at: sc.discarded_at,
              requires_signing: sc.requires_signing,
              allow_multiple_documents: sc.allow_multiple_documents,
              restricted_roles: sc.restricted_roles
            }
          })
        };

        axios({ url, method, data, headers }).then(res => {
          this.id = res.data.id
          this.name = res.data.name
          this.allow_multiple_documents = res.data.allow_multiple_documents
          this.requires_signing = res.data.requires_signing
          this.subCategories = res.data.sub_categories;
        });
      }
    }
  }
</script>
