import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    connect() {
        this.quicklink_elements = document.querySelectorAll('h6')
    }

    scroll(e) {
        this.quicklink_elements.forEach(el => {
            if (el.innerText == e.target.id) {
                el.scrollIntoView();
                return;
            }
        });
    }
}